@import "../../styles/colors.scss";

.builder-template {
  &__image {
    width: 220px;
    height: 154px;
    border-radius: 2px;
    object-fit: cover;
    user-select: none;

    &--placeholder {
      object-fit: contain;
    }
  }

  &__title {
    font-size: 19px;
    font-weight: 500;
    margin: 6px 0 0;
  }
}
