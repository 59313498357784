@import "../../styles/colors.scss";
@import "../../styles/pxtorem.scss";

.leads-table {
  padding: 0 15px;
  th.MuiTableCell-head {
    color: rgb(144, 144, 144);
    font-size: 14px;
    text-transform: uppercase;
  }

  tbody.MuiTableBody-root {
    border-top: 2px solid #ccc;
    border-bottom: none;
  }

  td.MuiTableCell-body {
    font-size: 13px;
    padding: 5px;
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .leads-table {
    .MuiTableCell-root {
      text-align: center;
      padding: 16px 5px;
    }
  }
}

@media (max-width: 480px) {
  .cart {
    .leads-table {
      .MuiTableRow-root {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}
