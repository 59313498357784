.job-card {
  margin-bottom: 30px;
  &__title {
    font-weight: bold;
    font-size: 24px;
  }
  &__description {
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;

    &__date {
      display: flex;
      align-items: center;

      & > div {
        padding-left: 20px;
      }
    }
  }
}
