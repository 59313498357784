@import "~/styles/colors.scss";

.my-business-add-bar {
  padding: 10px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $gray-background;

  &__title {
    font-size: 20px;
  }

  &__button {
    width: 80px;
  }
}
