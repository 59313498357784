@import "../../../styles/colors.scss";

.job-details-brief {
  margin: 20px;
  line-height: 2;

  &__title {
    font-size: 20px;
    font-weight: bold;
  }

  &__description {
    display: flex;
    // justify-content: space-between;
    &__block {
      display: flex;
      margin-right: 20px;
      &__name {
        margin-right: 10px;
      }

      &__data {
        font-weight: bold;

        &--primary-color {
          cursor: pointer;
          color: $primary-color;
          border-bottom: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid $primary-color;
          }
        }
      }
    }
  }

  &__anchors {
    display: flex;
    align-items: center;

    &__send-resume {
      margin-right: 5px;
    }

    &__social-link {
      cursor: pointer;
      display: flex;
      margin: 0 5px;
      border: 1px solid $gray-separator;
      padding: 2px;
      border-radius: 3px;

      &:hover {
        background-color: $gray-background;
      }

      &--facebook {
        svg {
          fill: $facebook;
        }
      }

      &--twitter {
        svg {
          fill: $twitter;
        }
      }

      &--linkedin {
        svg {
          fill: $linkedin;
        }
      }
    }
  }
}
