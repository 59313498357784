@import "../../styles/colors.scss";

html {
  background: #fff;
}

.business-card {
  font-size: 1em;
  width: 100%;
  height: 100vh;

  &__loading-spinner {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__vertical-separator {
    @include vertical-separator;
    align-self: stretch;
  }

  &--card-1 {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.25em;
      height: 1.25em;
    }

    div:not(:last-child) {
      margin-right: 0.625em;
    }
  }

  &--1 {
    background-color: $color-gray-light;
  }

  &--2,
  &--3,
  &--4 {
    background-color: $color-white;
  }

  &--5 {
    background-color: $color-white;
    background-image: url(../../assets/background/Back_Orange.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &--6 {
    background-color: $color-white;
    background-image: url(../../assets/background/Back_Red.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__body {
    max-width: 31.25em;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  &__flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__flex-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1.25em;
    }
  }

  &__separator {
    @include horizontal-separator;
  }

  &__u-padding-bottom {
    padding-bottom: 0.75em;
  }

  &__h {
    &--1 {
      font-size: 1.875em;
      font-weight: 500;
    }

    &--2 {
      font-size: 1.25em;
    }

    &--3 {
      font-size: 1.125em;
    }
    &--relative {
      padding-top: 4.0625em;
    }

    &--left {
      text-align: left;
    }

    &--center {
      text-align: left;
    }
  }

  &__p {
    font-size: 1em;

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--bold {
      font-weight: bold;
    }
  }

  &__flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__header {
    position: relative;
    min-height: 4.6875em;

    &--1 {
      background-color: $color-white;
      padding-bottom: 4.375em;
    }

    &--2 {
      background-color: $color-blue;
      padding-bottom: 4.375em;
    }

    &--3 {
      background-color: $color-green;
    }

    &--4 {
      background-color: $color-gray-dark;
    }

    &--5 {
      background-color: $color-orange;
    }

    &--6 {
      background-color: $color-red;
    }
  }

  &__logo {
    width: 6.25em;
    display: block;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;

    &--1 {
      transform: translate(-50%, -120%);
    }

    &--2 {
      transform: translate(-50%, -50%);
    }
  }

  &__relative {
    position: relative;
  }

  & &__company-logo {
    height: 5em;
    margin-right: 1.875em;
    box-shadow: 0 1em 1.875em rgba($color-gray, 0.4);
  }

  &__user-avatar {
    padding: 0.625em;
    margin: 1.25em 0;
    background-color: $color-white;
    box-shadow: 0 1em 1.875em rgba($color-gray, 0.4);
    border-radius: 0.1875em;
    overflow: hidden;
    width: 6.25em;

    &--round {
      border-radius: 50%;
    }

    &--relative {
      position: absolute;
      bottom: -8.125em;
      transform: translate(-50%, -50%);
    }
  }

  &__icon {
    margin-right: 1.25em;
    svg {
      width: 1.25em;
      height: 1.25em;
    }

    &--margin {
      margin-right: 3.125em;
    }

    &--round {
      padding: 0.625em;
      border-radius: 50%;
    }

    &--back-red {
      background-color: $color-red;
    }

    &--back-orange {
      background-color: $color-orange;
    }

    &--back-grey {
      background-color: $color-gray-dark;
    }

    &--back-green {
      background-color: $color-green;
    }

    &--color-white {
      svg {
        fill: #fff;
      }
    }

    &--color-black {
      svg {
        fill: #000;
      }
    }

    &--color-blue {
      svg {
        fill: $color-blue;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 0.3125em 1.25em;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
    }

    &--blue {
      background-color: $color-blue;
    }

    &--green {
      background-color: $color-green;
    }

    &--grey {
      background-color: $color-gray-dark;
    }

    &--orange {
      background-color: $color-orange;
    }

    &--red {
      background-color: $color-red;
    }
  }

  &__contacts {
    margin: 0 1.25em;

    &--1 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &__contact {
    margin-bottom: 1.25em;
    flex: 1;
  }

  &__contact-container {
    &--2,
    &--3,
    &--4,
    &--5 {
      display: flex;
    }
  }

  &__button-group {
    &--2,
    &--3,
    &--4,
    &--5,
    &--6 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__legal {
    padding: 1.25em 0;
  }
}

.business-card-user-info {
  display: flex;
  align-items: center;
  line-height: 1.5;

  &--1,
  &--2 {
    margin-top: -5.9375em;
  }

  &--3,
  &--4,
  &--5,
  &--6 {
    margin-top: 1.25em;
  }

  &--1,
  &--2,
  &--5,
  &--6 {
    flex-direction: column;
  }
  &__avatar-box {
    width: 8.125em;

    &--1,
    &--2,
    &--5,
    &--6 {
      margin: 1.25em 0 0.625em;
    }

    &--3,
    &--4 {
      margin-right: 1.875em;
    }

    &--1,
    &--2,
    &--3 {
      height: 8.125em;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      background-color: white;
    }
  }

  &__avatar {
    box-shadow: 0 1em 1.875em rgba($color-gray, 0.4);
    padding: 0.3125em;
    width: 100%;
    background-color: #fff;

    &--1,
    &--2,
    &--3 {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__name,
  &__surname {
    font-size: 1.875em;
    font-weight: 400;
  }

  &__profession {
    font-size: 1.25em;
  }
}

.business-card-company-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.25em;

  &--1 {
    justify-content: center;
  }

  &__logo-box {
    height: 5em;
  }

  &__logo {
    height: 100%;
    margin-right: 1.875em;
    box-shadow: 0 1em 1.875em rgba($color-gray, 0.4);

    &--1 {
      display: none;
    }
  }

  &__name {
    text-align: left;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 0.625em;

    &--1 {
      text-align: center;
    }
  }

  &__industry {
    color: grey;
  }
}

.business-card-company-description {
  text-align: left;
  margin: 0 1.25em;
  line-height: 1.6;
  margin-bottom: 1.25em;

  &--2 {
    text-align: center;
  }

  &__title {
    font-weight: 500;
  }

  &__description {
    color: grey;
  }
}

.business-card-contact-element {
  &--2,
  &--3,
  &--4,
  &--5,
  &--6 {
    display: flex;
    align-items: center;
  }

  &__upper-container {
    display: flex;

    &--1 {
      justify-content: center;
      align-items: center;
    }
  }

  &__container {
    &--1 {
      display: flex;
      justify-content: center;
      margin-top: 0.625em;
      color: grey;
    }

    &--2,
    &--3,
    &--4,
    &--5 {
      text-align: left;
      margin-left: 1.25em;
    }

    &--6 {
      display: flex;
    }
  }

  &__icon-box {
    width: 1.25em;
    height: 1.25em;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__icon-color {
    &--1 {
      svg {
        fill: #000;
      }
    }

    &--2 {
      svg {
        fill: $color-blue;
      }
    }

    &--3,
    &--4,
    &--5,
    &--6 {
      svg {
        fill: #fff;
      }
    }
  }

  &__icon-border-color {
    &--3,
    &--4,
    &--5,
    &--6 {
      padding: 0.625em;
      border-radius: 50%;
    }

    &--3 {
      background-color: $color-green;
    }

    &--4 {
      background-color: $color-gray-dark;
    }

    &--5 {
      background-color: $color-orange;
    }

    &--6 {
      background-color: $color-red;
    }
  }

  &__upper-title {
    margin-left: 0.625em;
  }

  &__title {
    font-size: 1em;
    font-weight: 500;

    &--6 {
      margin: 0 0.625em;
    }
  }

  &__content {
    font-size: 1em;

    &--2,
    &--3,
    &--4,
    &--5,
    &--6 {
      color: grey;
    }
  }
}

.business-card-social-links {
  margin: 0 1.25em;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &__icon-box {
    svg {
      width: 1.25em;
      height: 1.25em;
      font-size: 1.25em;
    }
    &--2:hover {
      svg {
        fill: $color-blue;
      }
    }

    &--3:hover {
      svg {
        fill: $color-green;
      }
    }

    &--4:hover {
      svg {
        fill: $color-gray-dark;
      }
    }

    &--5:hover {
      svg {
        fill: $color-orange;
      }
    }

    &--6:hover {
      svg {
        fill: $color-red;
      }
    }
  }
}

.business-card-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625em 1.25em;
  border-radius: 0.5em;
  color: #fff;
  min-width: 7.1875em;
  transition: all 0.2s;

  &__icon {
    display: flex;
  }

  svg {
    width: 1.25em;
    height: 1.25em;
    fill: #fff;
    margin-right: 0.625em;

    g {
      polygon {
        fill: #fff !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &--1 {
    background-color: $color-gray-dark;

    &:hover {
      background-color: darken($color: $color-gray-dark, $amount: 20);
    }
  }

  &--2,
  &--4 {
    background-color: $color-blue;

    &:hover {
      background-color: darken($color: $color-blue, $amount: 20);
    }
  }

  &--3 {
    background-color: $color-green;

    &:hover {
      background-color: darken($color: $color-green, $amount: 20);
    }
  }

  &--5 {
    background-color: $color-orange;

    &:hover {
      background-color: darken($color: $color-orange, $amount: 20);
    }
  }

  &--6 {
    background-color: $color-red;

    &:hover {
      background-color: darken($color: $color-red, $amount: 20);
    }
  }
}
