@import "../../styles/colors.scss";

.product-carousel {
  min-height: 13em;
  background: white;
  box-shadow: $box-shadow-color;
  padding: 16px 20px;
  border-radius: 5px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 2px 0 18px;

    & svg {
      position: relative;
      bottom: 1px;
      width: 23px;
    }

    &-text {
      font-weight: 700;
      margin: 0 10px;
    }
  }

  &__main {
    display: flex;

    & > .loading-spinner {
      height: 10em;
    }

    .product-carousel-item {
      & + .product-carousel-item:not(:nth-last-child(odd)) {
        margin: 0 12px;
      }
    }

    .img-card--products {
      width: 20%;

      .img-card-image {
        width: auto;
        height: 130px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .product-carousel {
    &__main {
      display: flex;
      justify-content: center;
      .img-card--products {
        width: auto;
        .img-card-image {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .product-carousel {
    .event-card {
      flex-direction: column;
    }
  }
}
