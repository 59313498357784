@import "../../styles/colors.scss";

.filter {
  box-shadow: $box-shadow-color;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;

  &__organization {
    padding-left: 12px;
  }
  &__header {
    display: flex;
    align-items: center;
    background: white;

    .icon {
      background: #e8e8e8;
      width: 80px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 25px;
        fill: rgba(99, 99, 99, 1);
      }
    }

    .country {
      padding: 0 20px;
      border-right: 1px solid grey;
    }

    .text {
      width: 100%;
      border: none;
      margin-left: 15px;
      &:focus {
        outline: none;
      }
    }
  }

  &__search-bar {
    width: 90%;
    margin: 0 10px;
  }
}

.filter[dir="rtl"] {
  &__organization {
    padding-right: 12px;
  }
  &__header {
    .text {
      margin-right: 15px;
    }
  }
}
