.search-bar {
  position: relative;

  &__input {
    border-radius: 5px;
    border: 1px solid lightgray;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 8px;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    position: absolute;
    fill: lightgray;
    top: 50%;
    transform: translate(0, -50%);

    [dir="ltr"] & {
      right: 1%;
      &.microphone {
        right: 40px;
      }
      @media screen and (max-width: 980px) {
        &.microphone {
          right: 15%;
        }
      }
    }
    [dir="rtl"] & {
      right: calc(100% - 30px);
    }
    &.microphone {
      width: 20px;
      height: 20px;
    }
  }
}
