.category-component {
  position: relative;

  &__editor {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
