.my-business {
  &__integration-monitor {
    margin: 20px 0;
    box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.15);
    padding: 20px 20px;
    background: #ffffff;
  }
}

.integration-monitor {
  &__container {
    margin: 20px 0 0;
  }

  &__item {
    &.error {
      background: #ff9999;
      color: #ffffff;

      .MuiAccordionSummary-expandIcon {
        color: #ffffff;
      }
    }
  }

  &__error-title {
    font-weight: bold;
  }

  &__item-time {
    margin: 10px 0 0;
  }
}
