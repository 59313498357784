@import "../../styles/colors.scss";

.filter-item {
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border: 1px solid $gray-separator;
  border-radius: 5px;
  padding: 0 5px;
  justify-content: space-between;
  [dir="ltr"] & {
    margin-right: 10px;
  }
  [dir="rtl"] & {
    margin-left: 10px;
  }

  &__data {
    [dir="ltr"] & {
      padding-right: 10px;
    }
    [dir="rtl"] & {
      padding-left: 10px;
    }
  }
}
