@import "../../styles/colors.scss";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  width: 94%;
  margin-top: 50px;

  .upper-header {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .header-logo-wrapper {
      display: flex;

      @media (max-width: 992px) {
        width: 90%;
      }
    }

    .burger-menu {
      display: none;
      cursor: pointer;
      g {
        g {
          path {
            fill: #4f4f4f;
          }
        }
      }
    }

    .logo-container {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .options {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 1.5vw;

      .option {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-light;
        font-size: 16px;
        font-weight: 400;
        line-height: 36.63px;
        text-transform: uppercase;
        margin: 0 20px;
        border-bottom: 4px solid transparent;
        cursor: pointer;

        & > div {
          position: absolute;
          right: -18px;
          display: flex;

          & > svg {
            width: 17px;
            height: 100%;
            opacity: 0.6;
          }
        }

        // &:hover {
        //   border-bottom: 4px solid $primary-color;
        // }
      }

      // &__active {
      //   border-bottom: 4px solid $primary-color !important;
      // }
    }
  }

  .icons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      margin: 10px 15px;
      cursor: pointer;
      &.user {
        margin-right: 0;
      }
      svg {
        g {
          fill: #fff;
          polygon {
            fill: #fff !important;
            color: #fff;
          }
          g {
            path {
              color: #fff;
              fill: #fff;
            }
          }
        }
      }

      &_language {
        font-weight: bold;
        color: white;
        font-size: 24px;
      }

      .user {
        padding: 10px 14px;
        border-radius: 50%;
        background: #ff8700;
      }
    }
  }

  &__user-logo-box {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  &__user-logo {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    position: relative;

    svg {
      position: relative;
    }
  }

  &__count-box {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px;
    background-color: red;
    border-radius: 50%;
    transform: translate(50%, -50%);
  }

  &__count {
    min-width: 15px;
    min-height: 15px;
    font-size: 10px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lower-header {
  max-width: 740px;

  .lower-links {
    display: flex;
    justify-content: center;

    .lower-link {
      cursor: pointer;
      font-weight: bold;
      font-size: 30px;
      margin: 15px 30px;
      color: #252525;
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom: 4px solid $primary-color;
      }

      &__active {
        border-bottom: 4px solid $primary-color;
      }
    }
  }
}

.login-tooltip {
  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  &__login-btn {
    padding-top: 10px;
  }

  &__separator {
    border-top: 1px solid lightgray;
  }

  &__padding {
    margin: 15px 20px;
  }

  &__footer {
    &__signup {
      color: $primary-color;
    }
  }
}

.profile-tooltip {
  &__separator {
    border-bottom: 1px solid lightgray;
  }

  &__link {
    padding: 5px 10px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f6f6f6;
    }

    svg {
      display: flex;
      width: 15px;
      height: 15px;
      fill: grey !important;
      margin-right: 10px;
    }

    &.business,
    &.pos,
    &.smartbot {
      background: rgba(#ff8700, 0.1);
    }
    // &.pos {
    //   background: #7dd577;
    // }
    // &.smartbot {
    //   background: #7797ff;
    // }
  }

  &__header {
    padding: 20px 25px;
    display: flex;
    align-items: center;
    > div + div {
      margin-left: 10px;
    }
    &__placeholder {
      width: 50px;
      height: 50px;
      background-color: #bcbcbc;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      svg {
        border-radius: 50%;
        width: 45px;
        height: 45px;
      }
    }
    h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      object-fit: scale-down;
    }

    &__details {
      [dir="ltr"] & {
        padding-left: 12px;
      }
      [dir="rtl"] & {
        padding-right: 12px;
      }
    }
  }

  &__body {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }
}

.nav-links-tooltip {
  &__content {
    padding: 18px 21px;
  }

  &__list {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
  }

  &__list-item {
    color: $color-text-light;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;

    &:hover {
      color: $color-orange;
      cursor: pointer;
    }
  }

  &__link {
    &:hover {
      color: $color-orange;
      cursor: pointer;
    }
  }
}

.rtl {
  .burger-menu {
    left: 6%;
    right: auto;
  }
  .login-tooltip__padding {
    h3,
    p {
      text-align: right;
    }
  }
  .profile-tooltip {
    &__header {
      &__details {
        h3,
        p {
          text-align: right;
        }
      }
    }
    &__body {
      a {
        text-align: right;
      }
    }
    &__link {
      justify-content: flex-end;
    }
    &__footer {
      a {
        text-align: right;
      }
    }
  }
}

@media (max-width: 1024px) {
  .header {
    .upper-header {
      flex-direction: column;

      .logo-container {
        width: 90%;
        justify-content: space-between;
      }

      .icons {
        .icon {
          padding: 0 10px;
          margin: 5px 15px;
        }
        .trigger {
          .icon {
            .user {
              background: #4f4f4f;
            }
          }
        }
      }

      .toggle-close {
        display: none;
      }

      .options {
        padding-top: 15px;
        padding-bottom: 15px;
        box-sizing: border-box;

        .option {
          margin: 7px 10px;
        }
      }
    }

    .lower-header {
      .lower-links {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .upper-header {
      .logo-container {
        width: 90%;
      }

      .burger-menu {
        display: block;
        margin-top: 10px;
      }

      .icons {
        .icon {
          padding: 0 3px;
        }
      }

      .options {
        flex-wrap: wrap;
        justify-content: center;

        .option {
          margin: 7px 10px;
        }
      }

      .icons {
        flex-direction: row;
        border-radius: 15px;
      }
    }
  }
}

@media (max-width: 480px) {
  .header {
    .upper-header {
      .logo-container {
        width: 90%;
      }

      .icons {
        .icon {
          padding: 5px 0;
        }
      }

      .icons {
        .icon {
          margin: 0 10px;
        }
      }
    }
  }
  .profile-tooltip {
    max-width: 300px;
    &__header {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 515px) {
  #widget-live {
    width: 100%;
    & > div {
      display: flex;
      justify-content: center;
    }
  }
  .header .icons {
    position: relative;

    & .icon {
      width: 100% !important;
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: 515px) {
  .header .icons .icon {
    position: relative;
  }
}
