@import "../../styles/colors.scss";
@import "../../styles/pxtorem.scss";

.event-table-body {
  &__img {
    width: 50px;
    object-fit: scale-down;
  }
  &__body {
    h5 {
      margin: 0;
      font-size: 14px;
    }

    &__description {
      p {
        font-size: 14px;
        color: gray;
      }
    }
    &__separator {
      @include horizontal-separator;
    }

    &__main-content {
      display: flex;
      // justify-content: space-between;

      &__header-icon {
        display: flex;
        padding-bottom: 12px;

        h5 {
          padding-left: 12px;
          font-size: 14px;
        }

        img {
          align-self: center;
        }
      }

      &__services {
        flex: 0.25;

        ul {
          padding-inline-start: 18px;
          margin: 8px 0 0 0;
          color: gray;
          font-size: 14px;
        }
      }

      &__products {
        flex: 0.25;
        &__imgs {
          display: flex;
          flex-wrap: wrap;
          img {
            padding: 5px 5px 0 0;
          }
        }
      }

      &__chat {
        flex: 0.55;
        &__btns {
          display: flex;
          flex-wrap: wrap;

          &__btn {
            padding: 10px 10px 0 0;
          }
        }
      }
    }

    &__meeting {
      h5 {
        color: $primary-color;
        font-size: 14px;
      }
      padding-top: 10px;
      &__date {
        padding-top: 10px;
        display: flex;
        align-items: center;

        input {
          padding: 0 10px;
        }

        label {
          padding: 0 10px;
        }
      }

      &__appoint {
        padding-top: 10px;
        display: flex;
        align-items: center;

        label {
          padding: 0 18px;
          font-size: 14px;
        }

        p {
          color: lightgray;
          font-size: 12px;
          padding-left: 18px;
        }

        &__separator {
          @include vertical-separator;
          height: 30px;
        }
      }
    }

    &__separator {
      @include horizontal-separator;
    }
  }

  &__company {
    h5 {
      margin: 0;
      font-size: 14px;
    }

    &__description {
      p {
        font-size: 14px;
        color: gray;
      }
    }

    &__offers-container {
      &__offer {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__news-container {
      &__news {
        display: flex;
      }
    }

    &__contacts {
      &__upper {
        display: flex;
        padding: 25px 0;

        &__data {
          display: flex;
          align-items: flex-start;
          flex: 1;
        }

        &__btn {
          align-self: center;
        }
      }

      &__icon {
        background-color: $primary-color;
        border-radius: 50%;
        padding: 8px 10px 5px;
        align-self: baseline;
        margin: 14px 12px 0 0;
      }
    }
  }
}
