.my-business-static-page-edit {
  padding: 14px 16px;

  &__grid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__column {
    flex-basis: 50%;

    &--sm {
      width: 30%;
      flex-basis: 30%;
    }

    &--lg {
      width: 70%;
      flex-basis: 70%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    &-column {
      width: 25%;
      margin: 7px;
    }
  }

  .news-gallery {
    &__header {
      font-size: 18px;
      font-weight: 700;
    }
    &__images {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .news-image {
        border: 1px solid #ccc;
        margin: 10px;
        img {
          width: 150px;
          height: 150px;
          object-fit: scale-down;
        }
      }
    }
  }

  &__captured {
    display: block;
    color: rgb(153, 153, 153);
    margin-bottom: 20px;
  }
}

.rtl {
  .my-business-static-page-edit {
    &__column--sm {
      padding-left: 0;
    }
    &__column--lg {
      padding-left: 0;
      padding-right: 18px;
    }

    .my-business-category-picker__input {
      label {
        margin-left: 0;
        margin-right: 14px;
      }
    }

    .default-dropdown-trigger--required,
    .my-business-category-picker__required,
    .input-group__input--required,
    .editor-group__required {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}

@media (max-width: 1024px) {
  .my-business-static-page-edit {
    &__column--sm,
    &__column--lg {
      width: auto;
    }
    &__column {
      padding-left: 0 !important;
    }
    &__grid {
      flex-direction: column;
    }
    &__column {
      padding-left: 0 !important;
      h2 {
        padding-top: 15px;
      }
    }
    .social-links-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .rtl .my-business-static-page-edit__column--lg {
    padding-right: 0;
  }
}
