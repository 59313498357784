@import "../../styles/colors.scss";

.company {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: $box-shadow-color;
  max-width: 750px;
  padding-bottom: 10px;
  margin-bottom: 120px;
  position: relative;

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0 45px;

    .nav-link {
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $primary-color;
      }

      &__separator {
        border-right: 1px solid lightgray;
      }
    }

    .nav-link-selected {
      color: $primary-color;
    }
  }
  .nav-additional {
    justify-content: center;
    margin: 10px;
    a {
      margin: 0px 25px;
    }
  }
  &__additional-tab {
    h3 {
      font-weight: normal;
      text-align: center;
      background-color: #fbfbfb;
      margin: 0;
      padding: 15px;
      word-wrap: break-word;
    }
  }
  &__additional-content {
    padding: 0 30px;
    p {
      word-wrap: break-word;
      img {
        display: block;
        width: 100%;
        height: auto;
        float: none !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .company {
    .organization-card {
      flex-direction: column;
      .logo-container {
        margin: 0 auto;
      }
      .info {
        margin: 0 auto;
      }
    }
    .nav {
      flex-wrap: wrap;
      justify-content: center;
      .nav-link {
        margin: 5px 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .company {
    .organization-card {
      .logo-container {
        padding-right: 0;
        margin-bottom: 15px;
      }
      .info {
        padding-left: 0;
        .title {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .company {
    .nav {
      padding: 0;
      a {
        text-align: center;
        margin: 5px;
      }
    }
  }
}
