.login-with-fb {
  &__fb-button {
    border: none;
    background: #fff;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #ccc;
    }
    &:focus {
      outline: none;
    }
  }
}
