.job {
  .filter {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .job {
    .MuiButtonBase-root {
      width: auto;
      span {
        p {
          padding: 10px;
        }
      }
    }
  }
}
