@import "../../styles/colors.scss";
.video-section {
  &__header {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__videos {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .single-video {
      position: relative;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 20px;
      outline: 2px solid transparent;
      transition: 0.3s ease-in-out;
      &:hover {
        outline: 2px solid #ccc;
      }
      &__link {
        img {
          width: 110px;
          height: 110px;
          object-fit: scale-down;
        }
      }
      &__clear {
        position: absolute;
        color: #262626;
        top: 0px;
        right: 0px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
