.company-small-card {
  position: relative;
  display: flex;
  margin: 15px 0;
  overflow: hidden;

  &__img {
    background-color: #fafafa;
    margin-right: 20px;
    width: 130px;
    height: 130px;

    img {
      width: 130px;
      height: 130px;
      background-size: contain;
    }
  }

  &__body {
    line-height: 1.5;

    &__name {
      font-size: 18px;
      font-weight: bold;
    }

    &__description {
      font-size: 13px;
    }

    &__contacts {
      display: flex;
      margin-top: 12px;

      &__contact {
        display: flex;

        &__data {
          margin: 0 12px;
        }
      }
    }
  }

  &__editor {
    position: absolute;
    left: 100%;
    transform: translate(-100%, 0);
  }
}

.rtl {
  .company-small-card {
    &__img {
      margin-left: 20px;
      margin-right: 0;
    }
  }
}
