@import "../../../styles/colors.scss";

.job-details-nav {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  &__vertical-separator {
    @include vertical-separator;
    margin: 0;
    width: 2px;
  }

  &__link {
    cursor: pointer;
    margin: 0 50px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid $primary-color;
    }

    &--selected {
      color: $primary-color;
    }
  }
}

@media (max-width: 480px) {
  .job-details-nav {
    flex-direction: column;
  }
}
