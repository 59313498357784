@import "../../styles/colors.scss";

.event-products {
  background: white;
  padding: 0 40px;
  margin-top: 15px;
  border-radius: 10px;

  &__sort {
    &__btns {
      display: flex;
      border: 1px solid lightgray;
      border-radius: 5px;

      & > span {
        border-left: 1px solid lightgray;
      }
    }
    &-upper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;

      &__selector {
        width: 48%;
      }
    }
  }

  &__card {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .event-products {
    &__sort-upper {
      flex-direction: column;
    }

    &__sort-bottom {
      flex-direction: column;

      &__selector {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .event-products {
    &__sort-upper {
      flex-direction: column;
    }
    &__sort__btns {
      flex-direction: column;
    }
  }
}
