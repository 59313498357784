@import "../../styles/variables.scss";

.item-card {
  width: 300px;
  margin: 7px;
  padding: 7px;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  &:hover {
    border: 1px solid #ccc;
  }
  &__image {
    img {
      width: 250px;
      height: 250px;
      object-fit: scale-down;
    }
  }
  &__content {
    .card-name {
      font-weight: $weight-fat;
    }
  }
}
