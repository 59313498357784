@import "../../styles/colors.scss";
@import "../../styles/pxtorem.scss";

.checkout-email {
  margin: 30px;

  &__company-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    .company-image {
      img {
        width: 120px;
        height: 120px;
        object-fit: scale-down;
      }
    }
    .company-name {
      padding-left: 10px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__company-totals {
    .totals-title {
      font-size: 24px;
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .final-total {
      max-width: 280px;
      line-height: 1.8;
      display: flex;
      justify-content: space-between;
    }
  }

  &__separator {
    @include horizontal-separator;
  }

  &__color-primary {
    color: $primary-color;
  }

  &__color-gray {
    color: $gray-text;
  }

  &__primary-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
  }

  &__secondary-title {
    margin: 10px 0;
    font-size: 22px;
    font-weight: bold;
  }

  &__tertiary-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;

    span {
      font-weight: normal;
    }
  }

  &__flex-container {
    display: flex;
  }
  &__flex-item {
    box-sizing: border-box;
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__confirm {
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    margin-left: 15px;
    width: 150px;
  }
  &__type-of-payment {
    display: flex;
    padding: 15px 0 10px;
    > div + div {
      margin-left: 10px;
    }
  }
}

.checkout-products-table {
  &__product-details {
    display: flex;
    align-items: center;

    &__image {
      img {
        width: 90px;
        width: 90px;
        object-fit: scale-down;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      padding-left: toRem(18px);

      &__title {
        font-size: toRem(12px);
        color: #919191;
      }

      &__offer {
        align-self: flex-start;
        background-color: #52ac62;
        border: toRem(1px) solid #52ac62;
        border-radius: toRem(3px);
        padding: toRem(5px) toRem(10px);
        font-size: toRem(12px);
        color: white;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;

    &__regular {
      font-weight: bold;
    }

    &__previous {
      font-size: toRem(12px);
      padding-left: toRem(6px);
      text-decoration: line-through;
    }
  }

  &__price-total {
    font-weight: bold;
    &_old {
      text-decoration: line-through;
      font-size: 12px;
    }
    &_discount {
      background: #ff9a44;
      font-size: 14px;
      padding: 2px 4px;
    }
  }
}

@media (max-width: 1024px) {
  .checkout-email {
    &__flex-container {
      flex-direction: column;
    }
    &__flex-item {
      margin-left: 0;
    }
    &__btn {
      margin-top: 10px;
    }
  }
}

@media (max-width: 480px) {
  .checkout-email {
    .MuiTableHead-root {
      .MuiTableRow-root {
        display: flex;
        flex-direction: column;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-alignLeft {
          text-align: center;
        }
        display: flex;
        flex-direction: column;
        .checkout-products-table__product-details {
          display: flex;
          flex-direction: column;
        }
      }
      .checkout-products-table__price {
        div {
          margin: 0 auto;
        }
      }
    }
  }
}
