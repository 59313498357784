.color-picker {
  &__label {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &__panel {
  }
}
