@import "../../styles/colors.scss";

.tooltip-container {
  background-color: white;
  border-radius: 3px;
  border: none;
  box-shadow: $box-shadow-color;
  display: flex;
  flex-direction: column;
  // margin: 0.4rem;
  // padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 2147483647;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}

.tooltip-arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-arrow[data-placement*="bottom"] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*="bottom"]::before {
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-arrow[data-placement*="bottom"]::after {
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-arrow[data-placement*="top"] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}

.tooltip-arrow[data-placement*="top"]::before {
  border-color: white transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-arrow[data-placement*="top"]::after {
  border-color: white transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-arrow[data-placement*="right"] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}

.tooltip-arrow[data-placement*="right"]::before {
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-arrow[data-placement*="right"]::after {
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-arrow[data-placement*="left"] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*="left"]::before {
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-arrow[data-placement*="left"]::after {
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
