.footer {
  background-image: url(../../assets/footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f3f3f3;
  width: 100%;

  .footer-container {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav-containers {
      display: flex;
      justify-content: space-around;

      .nav-container__flexibled {
        flex: 0.4;
      }

      .nav-description {
        font-weight: bold;
        font-size: 30px;
        padding-bottom: 50px;

        &__padding {
          padding-top: 50px;
        }

        &.nav-description-logo {
          padding-bottom: 0;
        }
      }

      .nav-links {
        .nav-link {
          padding: 5px 0;

          &_bold {
            font-weight: bold;
          }

          &_regular {
            font-weight: normal;
          }

          &_padding {
            padding-top: 35px;
          }
        }

        &__icons {
          display: inline-flex;
          border: 1px solid black;
          border-radius: 5px;

          & > span {
            border-left: 1px solid black;
          }
        }
        a:hover {
          border-bottom: 3px solid #252525;
        }
      }
    }

    .copy-right {
      padding-top: 60px;
    }
  }
}

@media (max-width: 1280px) {
  .footer {
    .footer-container {
      .nav-containers {
        flex-wrap: wrap;
        .nav-container {
          margin: 10px 25px;

          .nav-description {
            text-align: center;
            padding-bottom: 10px;

            &__padding {
              padding-top: 0;
            }
          }

          .nav-links {
            margin: 15px 0;
            text-align: center;

            p {
              padding: 0 10px 25px;
              width: 80%;
              margin: 0 auto;
            }
          }
        }
      }
      .copy-right {
        max-width: 85%;
        text-align: center;
      }
    }
  }
}
