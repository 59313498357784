@import "../../styles/pxtorem.scss";

.quantity-input-horizontal {
  display: flex;

  input {
    text-align: center;
    width: toRem(16px);
    height: toRem(6px);
    padding: toRem(10px);
  }
}
