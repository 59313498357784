@import "../../styles/colors.scss";

.react-daterange-picker {
  &__wrapper {
    align-items: center;
    padding: 0 10px;
    border: 1px solid lightgray;
  }

  &__button {
    margin-left: 12px;
  }
}

.react-calendar {
  &__tile--active:enabled:hover {
    background-color: $primary-color-darker;
  }

  &__tile--active:enabled:focus {
    background-color: $primary-color-darker;
  }
  &__tile--active {
    background-color: $primary-color;
  }

  &__tile--now {
    background-color: lightgray;
  }
}
