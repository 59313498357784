@import "../../styles/colors.scss";

.org-comp-tab {
  &__logo {
    width: 100px;
    height: 50px;
    object-fit: scale-down;
  }

  &__no-companies {
    @include no-data;
  }

  &__icon {
    position: relative;

    &:hover {
      & .org-comp-tab__icon-tooltip {
        display: block;
      }
    }
  }

  &__icon-tooltip {
    position: absolute;
    right: 0;
    display: none;
    background-color: lightgray;
    padding: 3px;
    border-radius: 5px;
    z-index: 10;
  }
}

.companies-content-table {
  padding: 0 30px;
  .inner-description {
    iframe {
      width: 160px;
      height: 90px;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: scale-down;
    }
  }
}
