@import "~/styles/colors.scss";

.event-card {
  display: flex;
  padding: 15px 0;
  cursor: pointer;
  position: relative;

  &__line-height {
    line-height: 1.4;
  }

  &__description {
    font-size: 13px;
  }

  &__country {
    display: inline-block;
    background-color: black;
    color: white;
    [dir="ltr"] & {
      margin: 0 5px 5px 0;
    }
    [dir="rtl"] & {
      margin: 0 0 5px 5px;
    }
    padding: 0 10px;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
  }

  &__img {
    padding: 0 10px;

    div {
      width: 160px;
      // height: 90px;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        // max-height: 100%;
        background-size: contain;
      }
    }
  }

  &__comp {
    font-size: 14px;
    color: $gray-text;

    &__branch {
      [dir="ltr"] & {
        padding-right: 15px;
      }
      [dir="rtl"] & {
        padding-left: 15px;
      }

      span {
        color: $primary-color;
      }
    }

    &__organization {
      span {
        color: $primary-color;
      }
    }
  }

  &__project-cost {
    font-size: 14px;
    color: $gray-text;

    span {
      color: black;
      font-weight: bold;
    }
  }

  &__publish {
    font-size: 14px;
    color: $gray-text;

    &__date {
      [dir="ltr"] & {
        padding-right: 15px;
      }
      [dir="rtr"] & {
        padding-left: 15px;
      }
    }

    &__company {
      color: $primary-color;
    }
  }

  &__editor {
    position: absolute;
    [dir="ltr"] & {
      left: 100%;
      transform: translate(-100%);
    }
    [dir="rtl"] & {
      right: 100%;
      transform: translate(100%);
    }
  }
}
