@import "../../styles/colors.scss";

.events {
  &__body {
    padding-bottom: 100px;
    margin-top: 10px;

    &__view {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-size: 13px;
      }

      &__block {
        padding: 0 10px;
      }
    }
    .event-card {
      &__img {
        width: 30%;
      }
      &__line-height {
        width: 70%;
      }
    }

    &__padding {
      padding: 30px;
    }
    &__title {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      background-color: $gray-background;
    }
  }
}

@media (max-width: 1024px) {
  .events {
    &__body__padding {
      .event-card {
        flex-direction: column;
        &__img {
          margin: 0 auto;
          width: auto;
        }
        &__line-height {
          padding: 15px 0;
          width: auto;
        }
      }
    }
  }
}
