@import "../../styles/colors.scss";

.event-files {
  &__padding {
    padding: 20px 0;
  }

  h3 {
    @include h3GrayBackground;
  }

  &__photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__photos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        padding: 10px;
      }
    }
  }

  &__video-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__video {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        padding: 10px;
      }
    }
  }

  &__documents-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__documents {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        padding: 10px;
      }
    }
  }
}
