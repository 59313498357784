@import "../../styles/colors.scss";

.cart {
  &__basket-tabs {
    padding: 10px 30px;
    display: flex;
    flex-wrap: wrap;
    &__nav {
      display: flex;
      padding: 6px 10px 4px;
      border: 1px solid #a0a0a0;
      border-radius: 5px;
      align-items: center;
      margin: 5px;
      transition: 0.5s;
      &:hover {
        border-color: $primary-color;
        color: $primary-color;
        svg {
          fill: $primary-color;
        }
      }
      &__icon {
        display: flex;
        align-items: center;
        svg {
          transition: 0.5s;
          fill: #7f7f7f;
        }
      }
      &__name {
        margin-left: 5px;
      }
      &__tab-active {
        border-color: $primary-color;
        color: $primary-color;
        svg {
          fill: $primary-color;
        }
      }
    }
  }
  .rtl {
    .cart__basket-tabs__nav {
      .cart__basket-tabs__nav__name {
        margin-right: 5px;
      }
    }
  }
}
