@import "../../../styles/colors.scss";

@mixin uppercaseTitle {
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin infoText {
  color: lightgray;
  font-size: 12px;
}

.my-business-events-edit {
  padding: 14px 16px;

  &__captured {
    display: block;
    color: rgb(153, 153, 153);
    margin-bottom: 20px;
  }

  &__grid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__column {
    flex-basis: 50%;

    &--sm {
      flex-basis: 30%;
    }

    &--lg {
      flex-basis: 70%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    &-column {
      width: 25%;
      margin: 7px;
    }
  }

  &__captured {
    display: block;
    color: rgb(153, 153, 153);
    margin-bottom: 20px;
  }

  &__info-text {
    @include infoText;
  }
}

.product-type-selector {
  &__title {
    @include uppercaseTitle;
  }

  &__info {
    @include infoText;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 6px 8px;
    width: 40%;
    text-align: center;

    &--selected {
      border-color: $primary-color;
      color: $primary-color;
      font-weight: bold;
    }
  }
}

.my-business-category-picker {
  &__title {
    @include uppercaseTitle;
  }

  &__required {
    color: red;
    font-size: 12px;
    margin-left: 12px;
  }

  &__info {
    @include infoText;
  }

  &__flex-container {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    margin-top: 5px;
  }

  &__flex-item {
    flex: 0.45;

    button:hover {
      border-color: $primary-color;
      color: $primary-color;
      background-color: transparent;
      transition: all 300ms ease;
    }
  }
}
