@import "../../styles/colors.scss";

.product-card {
  margin: 10px;
  position: relative;
  .MuiPaper-root {
  }
  &__image {
    width: 200px;
    height: 200px;
    object-fit: scale-down;
    position: relative;
    background-color: #fafafa;
  }

  &__product-details {
    padding: 16px;
    > div {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .product-name {
      font-size: 14px;
      height: 35px;
    }
    .product-description {
      font-weight: 700;
      height: 60px;
    }
    .product-price {
      font-weight: normal;
      color: $primary-color;
      height: auto;

      &__value {
        &_old {
          color: #cccccc;
          font-size: 12px;
          text-decoration: line-through;
        }
      }
    }
  }

  .discounts {
    position: absolute;
    z-index: 10;
    margin-top: 1rem;
    &__discount-percent,
    &__coupon-discount-percent {
      background-color: #d99a29;
      color: #ffffff;
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      padding: 2px 6px;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }

  .coupon-expires {
    position: absolute;
    width: 100%;
    top: 45%;
    display: flex;
    justify-content: space-evenly;
    > div {
      background: #cce669;
      padding: 6px 3px;
      border-radius: 6px;
    }
    &____days,
    &__hours,
    &__minutes,
    &__days {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      font-size: 24px;
      width: 40px;
      height: 50px;
      font-weight: bold;
      position: relative;
      .subtext {
        position: absolute;
        bottom: 2px;
        font-weight: 300;
        font-size: 15px;
      }
    }
  }
}
