.profile-card-preview {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }

  &__img-custom {
    display: block;
    margin: 0 auto;
    height: 100%;
  }

  &__img {
    position: relative;

    &__absolute {
      font-size: 3px;
      position: absolute;
      left: 19px;
      bottom: 71px;
      width: 168px;
      height: 302px;
      display: flex;
      justify-content: center;
      // card-style-bug
      align-items: baseline;
      overflow: hidden;

      &__img-body {
        object-fit: scale-down;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  &__transparent {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
