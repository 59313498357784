.subscription-form {
  display: flex;
  flex-direction: column;

  .subscription-form-button {
    width: 90px;
  }

  .subscription-form-label {
    color: white;
  }

  .subscription-form-btn-container {
    padding-top: 10px;

    .subscription-form-btn-text {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
