.my-business {
  &__documents {
    table {
      .table-div-content {
        max-width: 200px;
        word-wrap: break-word;
      }
      tbody {
        tr {
          td {
            text-align: -webkit-center;
            button {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .rtl {
    .MuiTableCell-root {
      div,
      a,
      .MuiButton-root {
        // margin: 0 -50% 0 0 !important;
      }
    }
    .my-business__upload-documents__document {
      input {
        margin-left: -100px;
      }
    }
  }

  .my-business {
    &__upload-documents {
      &__info {
        align-items: flex-end;
        .input-group {
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .my-business__documents {
    table {
      .table-div-content {
        display: flex;
        justify-content: center;
        word-break: break-word;
      }
    }
    .MuiTableCell-root {
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 13px;
      padding: 5px;
      div,
      a,
      .MuiButton-root {
        margin: 0 0 0 50%;
      }
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 30%;
    }
    td:before {
      position: absolute;
      top: 20px;
      left: 0px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    td:nth-of-type(1):before {
      content: "Name";
    }
    td:nth-of-type(2):before {
      content: "Description";
    }
    td:nth-of-type(3):before {
      content: "Visualize";
    }
    td:nth-of-type(4):before {
      content: "Remove";
    }
  }
}
