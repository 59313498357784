@import "../../../styles/colors.scss";

.my-business-leads-edit {
  padding: 14px 16px;
  h2 {
    text-align: center;
    margin-top: 0;
  }
  &__grid {
    &__row {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      > div + div {
        margin-left: 10px;
      }
      .input-group {
        width: 100%;
      }
      .editor-group {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    &-column {
      width: 25%;
      margin: 7px;

      & button {
        transition: all 300ms ease;

        &:hover {
          background: transparent;
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  }
}
