@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.categories-product-grid {
  background: transparent;
  box-shadow: none;
  padding: 0px;

  &__main {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  & .product-with-category {
    width: auto;

    @media screen and (max-width: $tablet) {
      flex-basis: 50%;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;

      & + .product-carousel-item {
        margin: 21px 0 0;
      }
    }
  }

  & .product-carousel-item {
    flex-basis: 33.3%;
    display: flex;
    align-items: center;

    @media screen and (min-width: $desktop-sm) {
      &:nth-last-child(-n + 3) {
        margin: 21px 0 0;
      }
    }

    @media screen and (max-width: $tablet) {
      flex-basis: 100%;

      & + .product-carousel-item {
        margin: 21px 0 0;
      }
    }

    & .MuiCard-root {
      display: flex;
      max-width: 100%;
      width: 100%;
    }

    & .MuiCardMedia-root {
      width: 73px;
      height: 73px;
    }

    &__actions {
      display: none;
    }

    &__product-details {
      flex: 1;
      padding: 0 7px;

      & > a {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    &__name {
      display: inline-block;
      height: auto;
      font-size: 12px;
    }

    &__price {
      justify-content: normal;
    }

    &__discount {
      width: 32px;
      margin: 0 4px;
    }
  }
}
