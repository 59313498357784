@import "../../styles/colors.scss";

.builder-site-item {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__web-data {
    line-height: 1.43;
    .web-data {
      &__title {
        a {
          font-size: 1.25rem;
          transition: 0.2s;
          &:hover {
            color: $primary-color;
          }
        }
      }
      &__link {
        a {
          color: rgba(0, 0, 0, 0.54);
          font-size: 0.875rem;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;

    .button-container {
      margin: 10px;
      svg {
        font-size: 20px;
      }
    }
  }

  &:nth-child(odd) {
    background-color: #fbfbfb;
  }
}

@media (max-width: 1024px) {
  .builder-site-item {
    flex-direction: column;
    &__web-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .web-data {
        &__title {
          a {
            font-size: 16px;
          }
        }
        &__link {
          a {
            font-size: 12px;
          }
        }
      }
      > div {
        margin: 0 10px;
      }
    }
    &__buttons {
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media (max-width: 480px) {
  .builder-site-item {
    flex-direction: column;
    &__web-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .web-data {
        &__title {
          a {
            font-size: 16px;
          }
        }
        &__link {
          a {
            font-size: 12px;
          }
        }
      }
      > div {
        margin: 0 10px;
      }
    }
    &__buttons {
      flex-direction: column;
      .button-container {
        margin: 5px;
      }
      justify-content: space-between;
    }
  }
}
