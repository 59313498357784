.button {
  &--regular {
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px 15px;
    &:hover {
      background-color: #f1f1f1;
    }
    &:focus {
      outline: 0;
    }
  }

  &--arrow {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 5px 15px;
    [dir="rtl"] & {
      transform: rotate(180deg);
    }
    &:focus {
      outline: 0;
    }
    &:hover {
      background-color: #f1f1f1;
    }
  }
}
