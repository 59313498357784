@import "~/styles/variables.scss";
@import "~/styles/colors.scss";

.upload-file-document {
  &__header {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__section {
    display: flex;
    > div {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &__upload-doc {
    display: flex;
    justify-content: center;
    input {
      [dir="ltr"] & {
        margin-left: 100px;
      }
      [dir="rtl"] & {
        margin-right: 100px;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    .button-wrapper {
      margin: 7px;
    }
  }
}

@media (min-width: $tablet) {
  .upload-file-document {
    &__section {
      > div + div {
        [dir="ltr"] & {
          margin-left: 15px;
        }
        [dir="rtl"] & {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .upload-file-document {
    &__section {
      flex-direction: column;
    }
  }
}
