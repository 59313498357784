.pelecard-page {
  &__title {
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
  }

  &__info {
    text-align: center;
    margin: 20px 30px;
  }

  &__btn-container {
    width: 150px;
    margin: 0 auto;
  }
}
