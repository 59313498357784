@import "../../../styles/colors.scss";

.my-business-products-edit {
  padding: 14px 16px;
  .product-date-remove {
    padding: 10px 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      color: #ccc;
    }
  }

  .business-recommendations {
    &__title {
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    &__small {
      color: lightgray;
      font-size: 12px;
      margin-bottom: 20px;
    }
    &__checkbox {
      input {
        width: 20px;
        height: 20px;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
}

// @mixin uppercaseTitle {
//   color: #262626;
//   font-size: 14px;
//   font-weight: 500;
//   text-transform: uppercase;
// }

// @mixin infoText {
//   color: lightgray;
//   font-size: 12px;
// }

// .my-business-products-edit {
// .product-type-selector {
//   &__title {
//     @include uppercaseTitle;
//   }

//   &__info {
//     @include infoText;
//   }

//   &__grid {
//     display: flex;
//     justify-content: space-between;
//   }

//   &__btn {
//     cursor: pointer;
//     border: 1px solid lightgray;
//     padding: 6px 8px;
//     width: 40%;
//     text-align: center;

//     &--selected {
//       border-color: $primary-color;
//       color: $primary-color;
//       font-weight: bold;
//     }
//   }
// }

// .my-business-category-picker {
//   &__title {
//     @include uppercaseTitle;
//   }

//   &__required {
//     color: red;
//     font-size: 12px;
//     margin-left: 12px;
//   }

//   &__info {
//     @include infoText;
//   }

//   &__flex-container {
//     display: flex;
//     justify-content: space-between;
//   }

//   &__input {
//     margin-top: 5px;
//   }

//   &__flex-item {
//     flex: 0.45;
//   }
// }
