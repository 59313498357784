@import "../../styles/colors.scss";

.builder-sites-search {
  &__wrapper {
    &.Mui-focused fieldset {
      border-color: $primary-color !important;
    }
  }

  &__input {
    padding: 12px 16px !important;
  }
}
