@import "../../styles/colors.scss";

.job-filter {
  background-color: white;
  display: flex;
  flex-direction: column;

  &__upper-btns {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;

    &__nav {
      width: 100%;
    }

    &__nav-active {
      background-color: $gray-background;
    }

    &__separator {
      @include vertical-separator;
      width: 3px;
      margin: 5px 0;
    }
  }

  &__lower-btns {
    display: flex;
    justify-content: center;
  }
}
