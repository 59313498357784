@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.event-meeting {
  &__table {
    .meeting-table {
      &__visitor {
        display: flex;
        align-items: center;
        .visitor-img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid $primary-color;
          background-color: #bcbcbc;
          margin-bottom: 5px;
        }
        .visitor-email {
          [dir="ltr"] & {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: $desktop-sm) {
  .meeting-list {
    .meeting-table {
      &__visitor,
      &__name,
      &__date,
      &__response {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 40%;
      img {
        margin: 0 auto;
      }
    }
    td:before {
      position: absolute;
      top: 40%;
      left: 5%;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    td:nth-of-type(1):before {
      content: "TITLE";
    }
    td:nth-of-type(2):before {
      content: "START/END";
    }
    td:nth-of-type(3):before {
      content: "VISITOR";
    }
    td:nth-of-type(4):before {
      content: "RESPONSE";
    }
  }
}
