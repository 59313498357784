.companies {
  .filter {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .main-body {
    .companies {
      .company-organization-card__body {
        display: flex;
        flex-direction: column;
      }
      .company-organization-card__footer {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
