.comp-org-contacts-bank {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 1em 0;
    padding: 0;
  }

  &__upper {
    padding: 0 40px;
  }
  &__lower {
    padding: 0 40px;
  }
}
