@import "../../styles/colors.scss";

.products-inner {
  .filter {
    margin-bottom: 10px;
  }
  .products {
    background: white;
    padding: 0 40px;
    border-radius: 5px;

    &__sort {
      &__btns {
        display: flex;
        border: 1px solid lightgray;
        border-radius: 5px;

        & > span {
          border-left: 1px solid lightgray;
        }
      }

      &-upper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > div {
          display: flex;
          align-items: center;
        }
      }

      &-bottom {
        display: flex;
        justify-content: space-between;

        &__selector {
          width: 48%;
        }
      }
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .products {
    &__cards {
      justify-content: space-around;
    }
  }
}

@media (max-width: 1024px) {
  .products-inner {
    .products {
      &__sort-upper {
        flex-direction: column;
      }

      &__sort-bottom {
        flex-direction: column;

        &__selector {
          margin-bottom: 20px;
          width: 100%;
        }
      }

      &__cards {
        justify-content: space-around;
      }
    }
  }
}

@media (max-width: 767px) {
  .products-inner {
    .products__sort {
      &__sort-upper {
        flex-direction: column;
      }

      &__sort-bottom {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 480px) {
  .products-inner {
    .products {
      &__sort__btns {
        flex-direction: column;
      }
    }
  }
}
