@import "~/styles/colors.scss";

.media-actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  &__image-wrapper {
    height: 200px;
    width: 100%;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  &__avatar {
    border-radius: 50%;
  }

  &__logo {
    border: 1px solid lightgray;
    margin-top: 30px;
    width: 210px;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__btn {
    width: 100%;
    padding-top: 7px;
  }
}
