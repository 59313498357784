@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.news {
  &__block {
    margin-bottom: 10px;
    &--padding {
      padding: 15px 25px 0;
      display: flex;
    }
    &--categories {
      padding: 15px 25px 0;
      display: flex;
      .news-categories {
        padding: 0 10px;
      }
    }
  }

  &__separator {
    @include horizontal-separator;
    margin: 0;

    &:last-child {
      display: none;
    }
  }
}

@media (max-width: $tablet) {
  .news {
    &__block--padding {
      .event-card {
        width: 80%;
        margin: 0 auto;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: $phone-lg) {
  .news {
    &__block--categories {
      flex-direction: column;
      align-items: center;
      > div {
        margin-bottom: 10px;
      }
    }
  }
}
