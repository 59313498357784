.language-switcher {
  & &__select {
    background: transparent !important;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 0 0 2px 0 !important;
  }
  .MuiInput-root {
    .MuiSelect-select {
      min-height: auto !important;
    }
  }
}
