.my-person-orders {
  .deals-container {
    table {
      .MuiTableBody-root {
        border-top: 4px solid #ccc;
        border-bottom: 4px solid #ccc;
        .MuiTableCell-root {
          padding: 16px 8px !important;
        }
        td {
          padding: 16px 8px !important;
        }
      }

      .MuiTableCell-root {
        padding: 16px 8px;
      }

      .order-details {
        &__description {
          max-width: 130px;
          padding-left: 10px;
          .description-category {
            font-size: 0.75rem;
            color: #919191;
          }
        }
        .order-img {
          width: 100px;
          height: 100px;
          object-fit: scale-down;
        }
      }
    }
  }
}
