@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.product-variable {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    img {
      object-fit: scale-down;
      width: 100px;
    }
  }
  &__color {
    display: flex;
    justify-content: center;
    .product-color {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;

    .action-edit {
      width: 20px;
    }
    a {
      svg {
        margin-right: 10px;
      }
    }
    svg {
      color: $color-gray-dark;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
@media (max-width: $desktop-sm) {
  .product-variable {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 40%;
      img {
        margin: 0 auto;
      }
    }
    td:before {
      position: absolute;
      top: 40%;
      left: 5%;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    td:nth-of-type(1):before {
      content: "TITLE";
    }
    td:nth-of-type(2):before {
      content: "START/END";
    }
    td:nth-of-type(3):before {
      content: "VISITOR";
    }
    td:nth-of-type(4):before {
      content: "RESPONSE";
    }
  }
}
