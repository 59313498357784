@import "../../styles/colors.scss";

.cart-order-summary {
  display: flex;

  &__plus {
    align-self: center;
    padding-bottom: 36px;
  }

  &__body {
    line-height: 1.8;
    width: 200px;
    padding: 10px 25px;

    &__subtotal {
      font-weight: bold;

      &__span {
        color: $primary-color;
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }

    &__total {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
  }

  &__separator {
    height: 60px;
    width: 1px;
    margin: 24px 5px 0;
    background-color: lightgray;
  }
}
