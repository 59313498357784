@import "../../styles/colors.scss";

.business-proposals {
  &__body {
    padding-bottom: 100px;
    margin-top: 30px;

    &__title {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      background-color: $gray-background;
    }

    &__padding {
      padding: 0 30px;
    }
    &__title {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      background-color: $gray-background;
    }
  }
}

@media (max-width: 480px) {
  .business-proposals {
    .manage-block {
      flex-direction: column;
    }
  }
}
