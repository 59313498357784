@import "~/styles/colors.scss";

.productrequest-form {
  &__wrapper {
    padding: 14px 16px;

    h2 {
      text-align: center;
      margin-top: 0;
    }
  }

  &__container {
    &__row {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      > div + div {
        margin-left: 10px;
      }
      .input-group {
        width: 100%;
      }
      .editor-group {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &__complete {
    padding: 15px;
    border: 1px solid $primary-color-darker;
  }
}

.form-dropdown {
  &__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0 0 20px;
    background: $primary-color-darker;
    color: #fff;
  }
}
