.clients-company {
  &__inner-content {
    position: relative;
    padding: 15px 35px;
    display: flex;
    align-items: center;
  }
  &__table-container {
    .contact-links {
      display: flex;
      align-items: center;
      flex-direction: column;

      svg:not(:last-child) {
        margin: 0 0 10px;
      }
    }
  }
  &__name {
    font-size: 20px;
    font-weight: bold;
  }
  &__company-img {
    width: 60px;
    height: 60px;
    object-fit: scale-down;
  }
}

.client-image {
  width: 60px;
  height: 60px;
  object-fit: scale-down;
}

@media (max-width: 1024px) {
  .clients-company__table-container {
    padding: 0;
    .MuiTableCell-root {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div,
      a,
      .MuiButton-root {
      }
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 40%;
      img {
        margin: 0 auto;
      }
    }
    td:before {
      position: absolute;
      top: 40%;
      left: 5%;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    .contact-links {
      max-width: 150px;
      margin: 0 auto;
      svg {
        fill: #ccc;
        margin: 0 15px;
      }
    }
    .client-links {
      justify-content: center;
    }

    td:nth-of-type(1):before {
      content: "PHOTO";
    }
    td:nth-of-type(2):before {
      content: "NAME";
    }
    td:nth-of-type(3):before {
      content: "DEPARTMENT";
    }
    td:nth-of-type(4):before {
      content: "POSITION";
    }
    td:nth-of-type(5):before {
      content: "CONTACT";
    }
    td:nth-of-type(6):before {
      content: "STATUS";
    }
  }
}

@media (min-width: 480px) {
  [dir="ltr"] .clients-company__image-container {
    margin-right: 15px;
  }
}

@media (max-width: 480px) {
  .clients-company {
    &__inner-content {
      flex-direction: column;
    }
  }
}
