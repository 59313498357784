@import "../../styles/colors.scss";

.navbar {
  display: none;
  margin: 0 0 60px;

  @media screen and (min-width: 992px) {
    max-width: 300px;
  }

  &--open {
    display: flex;
    flex-flow: column;
  }

  &__section {
    &--categories {
      background-color: #ffffff;
      color: $color-text-light;
      line-height: 16px;
      padding: 20px 14px;
      border-radius: 2px;
      box-shadow: $box-shadow-color;
    }

    &--buttons {
      margin: 16px 0 0;
    }
  }

  & .trigger {
    display: flex;
    padding: 0 0 25px;

    &:last-child {
      padding: 0;
    }

    & > a {
      color: inherit;
    }

    &:hover {
      color: $color-orange;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 10px 14px;
    font-weight: 700;
    line-height: 32px;
    padding: 5px 14px;
    border: none;
    border-radius: 4px;

    &-text {
      margin: 0 18px;
    }

    &:hover {
      cursor: pointer;
    }

    &--orange {
      background-color: $color-orange;
      color: #ffffff;

      &:hover {
        background-color: $primary-color-darker;
      }
    }
  }
}

.navbar-menu-item {
  &__menu {
    display: flex;
    align-items: center;

    & > svg {
      width: 32px;
      opacity: 0.5;
    }
  }

  &__name {
    flex: 1;
    display: flex;
    color: inherit;
    font-size: 16px;
    padding: 0 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &__submenu {
    padding: 18px 21px;
  }

  &__list {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    columns: 2;
  }

  &__list-item {
    color: $color-text-light;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;

    &:hover {
      color: $color-orange;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
