@import "./../../styles/variables.scss";

.profile-business-links {
  h2 {
    font-weight: 500;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  &__container {
    background-color: #fff;
    margin-bottom: 14px;
    display: flex;
    height: 32px;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  &__icon {
    padding: 0 10px;
    cursor: pointer;
  }

  &__separator {
    border-left: 1px solid lightgray;
    height: 32px;
    [dir="rtl"] & {
      padding-right: 10px;
    }
    [dir="ltr"] & {
      padding-left: 10px;
    }
  }

  &__input {
    border: none;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }
}
