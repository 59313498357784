@import "../../styles/colors.scss";
@import "../../styles/variables.scss";
.profile {
  background: #ffffff;
  padding: 30px 30px 0;

  &__flex {
    display: flex;
    justify-content: space-between;

    &__separator-vertical {
      border-left: 1px solid lightgray;
    }

    &__separator-horizontal {
      border-bottom: 1px solid lightgray;
      margin: 20px 0;
    }

    &__grow {
      flex: 0.9;

      &__builder {
        margin: 25px 0;
        background-color: $gray-background;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  &__flex-card {
    flex-basis: 0;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    &__btns {
      display: flex;
      justify-content: space-between;
      width: 250px;
    }
  }
}

@media (max-width: $desktop-sm) {
  .profile {
    &__flex {
      flex-direction: column;
      &__grow {
        &__builder {
          flex-direction: column;
          > div {
            margin: 5px;
          }
        }
      }
    }
  }
}
