@import "~/styles/colors.scss";

.default-dropdown {
  &__label {
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__container {
    @include custom-scroll-bar;
  }

  &__body {
    background-color: #fff;
    border: 1px solid lightgray;
    border-top: none;
    padding: 6px 12px;
    overflow: auto;
    max-height: 110px;
  }

  &__input-container {
    position: relative;

    &__input {
      border-radius: 5px;
      border: 1px solid lightgray;
      margin: 14px 0;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 8px;

      &:focus {
        outline: none;
      }
    }

    &__icon {
      position: absolute;
      fill: lightgray;
      top: 21px;
      right: 8px;
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__item:last-child {
    margin-bottom: 0;
  }
}

.default-dropdown-trigger {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #444;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  border: 1px solid lightgray;

  &--required {
    color: red;
    font-size: 12px;
    margin-left: 12px;
  }

  &:focus {
    outline: none;
  }

  &__icon {
    div {
      display: flex;
    }
  }
}
