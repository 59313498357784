@import "../../styles/colors.scss";

.product-specification-about {
  h3 {
    @include h3GrayBackground;
    padding: 25px 0;
  }
  &__table {
    margin: 15px;
  }
  &__row {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
  &__label {
    width: 40%;
    font-weight: 600;
    padding: 15px 10px;
    text-transform: capitalize;
  }
  &__value {
    width: 60%;
    padding: 15px 10px;
  }
}
