@import "~/styles/colors.scss";

.default-checkbox-container {
  display: flex;
  align-items: center;
  &__title {
    [dir="ltr"] & {
      margin-left: 10px;
    }
    [dir="rtl"] & {
      margin-right: 10px;
    }
    color: #262626;
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    padding: 0;
    &:focus {
      border: none;
    }
  }

  & + label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 25px;
    height: 25px;
    background: white;
    border: 1px solid #ccc;
  }

  &:hover + label:before {
    background: #f0f0f0;
    transition: all 0.3s ease-in-out;
  }
  &:hover + label:after {
    transition: all 0.3s ease-in-out;
  }

  &:checked + label:before {
    background: #fff;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 12px;
    background: $primary-color;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 $primary-color, 4px 0 0 $primary-color,
      6px 0 0 $primary-color, 8px 0 0 $primary-color, 8px -2px 0 $primary-color,
      8px -4px 0 $primary-color, 8px -6px 0 $primary-color,
      8px -8px 0 $primary-color, 8px -10px 0 $primary-color,
      8px -12px 0 $primary-color, 8px -12px 0 $primary-color,
      8px -14px 0 $primary-color, 8px -16px 0 $primary-color;
    transform: rotate(45deg);
  }
}
