@import "../../styles/colors.scss";

.cart {
  h3 {
    text-align: center;
    font-weight: normal;
  }
  h4 {
    @include h3GrayBackground;
  }

  &__upper-nav {
    display: flex;
    justify-content: space-between;
    a {
      width: 50%;
    }
  }

  &__heading {
    @include h3GrayBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__button {
      position: absolute;
      left: 20px;

      &__icon {
        position: relative;
        transform: translate(20%);
        fill: grey !important;
      }
    }

    &__title {
      font-size: 20px;
    }
  }
}
