.we-recommend {
  padding: 15px 30px;
  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .we-product-card {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin: 15px;
      max-width: 200px;
      &__img {
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      &__content {
        padding: 10px 20px;
        line-height: 1.5;
        .content-name {
          font-size: 14px;
          height: 35px;
        }
      }
    }
  }
}
