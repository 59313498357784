@import "../../styles/colors.scss";

.my-business {
  margin-bottom: 40px;
  &__vertical-separator {
    background-color: $gray-separator;
    width: 2px;
  }

  &__horizontal-separator {
    background-color: $gray-separator;
    height: 1px;
  }

  &__margin {
    margin: 0px 35px;
  }

  &__heading {
    @include h3GrayBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__button {
      position: absolute;
      left: 20px;

      &__icon {
        position: relative;
        transform: translate(20%);
        fill: grey !important;
      }
    }

    &__title {
      font-size: 20px;
    }
  }

  &__upper-nav {
    display: flex;
    justify-content: space-between;
    a {
      width: 50%;
    }
  }

  &__lower-nav {
    .lower-nav-wrapper {
      display: flex;
      justify-content: flex-start;
      padding: 15px 30px 7px;
      flex-wrap: wrap;
      &__link {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 3px 8px;
        border: 1px solid #a0a0a0;
        border-radius: 5px;
        margin: 0 4px 8px;
        transition: 0.5s;
        .link-icon {
          svg {
            fill: grey;
          }
        }
        .link-name {
          [dir="ltr"] & {
            margin-left: 5px;
          }
          [dir="rtl"] & {
            margin-right: 5px;
          }
        }
        &:hover {
          background-color: $grey-active;
        }
      }
      &__link-active {
        background-color: $grey-active;
      }
    }
  }
  //   &__wrapper {
  //     display: flex;
  //     margin: 5px 0;
  //     justify-content: space-around;
  //   }

  //   .my-business__vertical-separator {
  //     width: 1px;
  //   }

  //   &__container-active {
  //     background-color: $grey-active;
  //   }

  //   &__container {
  //     cursor: pointer;
  //     font-size: 14px;
  //     display: flex;
  //     align-items: center;
  //     padding: 0 9px;

  //     &:hover {
  //       background-color: $grey-active;
  //     }

  //     &__name {
  //       margin-left: 10px;
  //     }

  //     &__icon {
  //       svg {
  //         fill: grey;
  //       }
  //     }
  //   }
  // }
}

.rtl {
  .my-business__lower-nav__wrapper {
    .my-business__lower-nav__container__name {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .company-small-card__editor {
    left: 15%;
  }
}

@media (max-width: 1024px) {
  .my-business {
    &__lower-nav {
      &__wrapper {
        flex-wrap: wrap;
        justify-content: center;
        a {
          padding: 3px 9px;
        }
      }
    }
    .deals-container {
      .MuiTableCell-root {
        padding: 16px 0;
      }
    }
    .my-business-events {
      .event-card {
        &__img {
          padding: 10px 0;
        }
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 480px) {
  .my-business {
    .my-business-companies {
      .company-small-card {
        display: flex;
        flex-direction: column;
      }
    }
    .my-business-news {
      .event-card {
        display: flex;
        flex-direction: column;
      }
    }
    .deals-container {
      .MuiTableHead-root {
        .MuiTableRow-root {
          flex-direction: column;
          display: flex;
        }
        .MuiTableCell-root {
          padding: 16px 0;
          font-size: 12px;
        }
      }
      .MuiTableBody-root {
        .order-details {
          display: flex;
          flex-direction: column;
        }
        .MuiTableRow-root {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
