.login-with-linkedin {
  button {
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
