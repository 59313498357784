@import "../../../styles/colors.scss";
@import "../../../styles/variables.scss";

.my-business-meeting-invitations {
  padding: 20px 0;
  &__table {
    .meeting-invitation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
      &__description {
        padding: 3px 0;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          object-fit: scale-down;
        }
        .description-name {
          font-size: 18px;
          [dir="ltr"] & {
            padding-left: 15px;
          }
          [dir="rtl"] & {
            padding-right: 15px;
          }
        }
      }
      &__btns {
        padding: 5px 0;
        display: flex;
        button[disabled] {
          color: #fff;

          &:first-child {
            background-color: $primary-color;
          }
          &:last-child {
            background-color: $primary-danger-red;
          }
        }

        > button + button {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .my-business-meeting-invitations {
    padding: 20px 0;
    &__table {
      .meeting-invitation {
        flex-direction: column;
      }
    }
  }
}
