@import "../../styles/colors.scss";
@import "../../styles/pxtorem.scss";

.cart-products-table {
  padding: 0 30px;
  .remove-items {
    cursor: pointer;
  }
  &__product-details {
    display: flex;
    align-items: center;

    &__image {
      img {
        max-width: 90px;
        max-height: 90px;
        background-size: contain;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      padding-left: toRem(18px);

      &__title {
        font-size: toRem(12px);
        color: #919191;
      }

      &__offer {
        align-self: flex-start;
        background-color: #52ac62;
        border: toRem(1px) solid #52ac62;
        border-radius: toRem(3px);
        padding: toRem(5px) toRem(10px);
        font-size: toRem(12px);
        color: white;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;

    &__regular {
      font-weight: bold;
    }

    &__previous {
      font-size: toRem(12px);
      padding-left: toRem(6px);
      text-decoration: line-through;
    }
  }

  &__price-total {
    font-weight: bold;
  }
}

.rtl {
  .cart-products-table__product-details {
    &__text {
      text-align: right;
      padding-left: 0;
      padding-right: toRem(18px);
    }
  }
}

@media (max-width: 1024px) {
  .cart-products-table {
    .MuiTableCell-root {
      text-align: center;
      padding: 16px 5px;
    }
  }
}

@media (max-width: 767px) {
  .cart-products-table {
    &__product-details {
      max-width: 250px;
      display: flex;
      flex-direction: column;
    }
  }
  .cart-total-price {
    justify-content: center !important;
  }
  .cart-order-summary {
    flex-direction: column;
    align-items: center;
    &__separator {
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 480px) {
  .cart {
    .cart-products-table {
      .MuiTableRow-root {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .cart-total-price {
      justify-content: center;
    }
    .cart-order-summary {
      &__body {
        &__subtotal {
          text-align: center;
        }
      }
    }
  }
}
