@import "../../styles/colors.scss";

.MuiPaper-root .builder-sites-card {
  &__header {
    background-color: rgb(250, 250, 250);
    text-align: center;
    padding: 16px;
    font-size: 24px;
  }
  &__main {
    padding: 25px 32px;
    .builder-list {
      padding: 8px 0;
    }
  }

  [dir="ltr"] & {
    padding: 50px;
  }
  &__main {
    padding: 25px 32px;
  }

  &__empty {
    margin: 4px 0;
    padding: 8px 16px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px 32px;
  }
}

@media (max-width: 1024px) {
  .builder-sites-card {
    &__footer {
      display: flex;
      flex-direction: column;
    }
  }
}
