@import "../../styles/colors.scss";

.custom-checkbox-container {
  position: relative;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  cursor: pointer;
  position: absolute;
  top: -10px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid $primary-color;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
