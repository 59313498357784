.my-business-event-invitations {
  padding: 0 0 30px;
  &__event-data {
    display: flex;
    align-items: center;
    > div + div {
      margin-left: 20px;
    }
    .event-image {
      img {
        width: 60px;
        height: 60px;
        object-fit: scale-down;
      }
    }
    .event-title {
      font-size: 18px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }
}
