@import "~/styles/variables.scss";

.parent-element-wrapper {
  margin-bottom: 15px;
  display: flex;
  > div {
    width: 100%;
  }
}

@media (min-width: $desktop-sm) {
  .parent-element-wrapper {
    > div + div {
      [dir="ltr"] & {
        margin-left: 15px;
      }
      [dir="rtl"] & {
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: $desktop-sm) {
  .parent-element-wrapper {
    flex-direction: column;
    > div {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
