.static-page {
  &__header {
    display: flex;
    align-items: center;
    padding: 20px 0;
    [dir="rtl"] & {
      svg {
        transform: scaleX(-1);
      }
    }

    .static-header-title {
      font-size: 30px;
      font-weight: bold;
      margin: 0 25px;
      cursor: pointer;
    }
  }
  &__content {
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    iframe {
      width: 100%;
    }
  }
}
