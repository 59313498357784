.wish-list-container {
  .wish-list-inner {
    padding: 0 30px;
    &__table {
      tbody {
        border-bottom: none;
      }
      .wish-product-img {
        width: 70px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .wish-list-container {
    .wish-list-inner {
      &__table {
        .MuiTableCell-root {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 13px;
          padding: 5px;
          div,
          a,
          .MuiButton-root {
            margin: 0 0 0 50%;
          }
        }
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border-bottom: 1px solid #ccc;
        }
        td {
          text-align: center !important;
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 40% !important;
          img,
          input,
          button {
            margin: 0 auto !important;
          }
          img {
            width: auto !important;
            height: 40px;
          }
          a {
            margin: 0 auto !important;
          }
        }
        td:before {
          position: absolute;
          top: 20px;
          left: 0px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
          font-weight: bold;
        }

        td:nth-of-type(1):before {
          content: "";
        }
        td:nth-of-type(2):before {
          content: "PRODUCT";
        }
        td:nth-of-type(3):before {
          content: "NAME";
        }
        td:nth-of-type(4):before {
          content: "COST";
        }
        td:nth-of-type(5):before {
          content: "";
        }
        td:nth-of-type(6):before {
          content: "";
        }
      }
    }
  }
}
