@import "~/styles/colors.scss";

.default-select-input {
  max-width: 400px;
  &__title {
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__flex-container {
    display: flex;
  }

  &__input {
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 13px;

    [dir="ltr"] & {
      border-left: 1px solid transparent;
    }
    [dir="rtl"] & {
      border-right: 1px solid transparent;
    }
    &:hover {
      border: 1px solid $primary-color;
    }
    &:focus {
      outline-color: $primary-color;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
