@import "./../../../styles/colors.scss";
@import "./../../../styles/variables.scss";

.my-business-event-invitations-inner-info {
  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    .header-invitations-tab {
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      padding: 15px;
      font-size: 18px;
      &:not(:last-child) {
        [dir="ltr"] & {
          border-right: 1px solid #eaeaea;
        }
        [dir="rtl"] & {
          border-left: 1px solid #eaeaea;
        }
      }
      &__active {
        background-color: #ededed;
        font-weight: 700;
      }
    }
  }

  &__meeting-section {
    .meeting-add-user {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 0;
      }
    }
    .meeting-list {
      .meeting-table {
        &__visitor {
          display: flex;
          align-items: center;
          .visitor-img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid $primary-color;
            background-color: #bcbcbc;
            margin-bottom: 5px;
          }
          .visitor-email {
            [dir="ltr"] & {
              padding-left: 5px;
            }
            [dir="rtl"] & {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  &__main {
    .create-meeting {
      > div + div {
        margin-top: 10px;
      }
      &__inputs {
        display: flex;
        > div + div {
          [dir="ltr"] & {
            margin-left: 10px;
          }
          [dir="rtl"] & {
            margin-right: 10px;
          }
        }
        .input-group,
        .default-textarea {
          width: 100%;
        }
        .input-event-time {
          width: 100%;
          display: flex;
          flex-direction: column;
          label {
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          input {
            border: 1px solid lightgray;
            padding: 7px 10px;
          }
          > input + input {
            margin-top: 10px;
          }
        }
      }
      &__footer {
        display: flex;
        justify-content: center;
        .footer-btn {
          width: 25%;
          margin: 7px;
        }
      }
    }
  }
  &__table-data {
    .events-invitations-table {
      &__person-image,
      &__company-image {
        display: flex;
        align-items: center;
        .user-image {
          img,
          svg {
            width: 40px;
            height: 40px;
            object-fit: scale-down;
          }
        }
        .user-name {
          [dir="ltr"] & {
            padding-left: 15px;
          }
          [dir="rtl"] & {
            padding-right: 15px;
          }
        }
      }
      &__person-image {
        .user-image {
          img,
          svg {
            border-radius: 50%;
            border: 2px solid $primary-color;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-sm) {
  .my-business-event-invitations-inner-info {
    &__main {
      padding: 15px 30px;
    }
  }
}
@media (max-width: $desktop-sm) {
  .my-business-event-invitations-inner-info {
    &__main {
      padding: 10px;
    }
    &__table-data {
      .events-invitations-table {
        &__person-image,
        &__email,
        &__phone {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          word-break: break-all;
        }
      }

      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border-bottom: 1px solid #ccc;
      }
      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 40%;
        img {
          margin: 0 auto;
        }
      }
      td:before {
        position: absolute;
        top: 40%;
        left: 5%;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      td:nth-of-type(1):before {
        content: "NAME";
      }
      td:nth-of-type(2):before {
        content: "EMAIL";
      }
      td:nth-of-type(3):before {
        content: "PHONE";
      }
      td:nth-of-type(4):before {
        content: "COMMENT";
      }
    }
  }
}
@media (max-width: $phone-lg) {
  .my-business-event-invitations-inner-info {
    &__header {
      flex-direction: column;
    }
  }
}
