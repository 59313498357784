.coupon-container {
  &__sort {
    padding: 10px 30px;
  }
  &__sort-upper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
      display: flex;
      align-items: center;
    }
  }
  &__sort-bottom {
    display: flex;
    justify-content: space-between;
  }
  &__sort-bottom__selector {
    width: 48%;
  }
  &__cards {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product-card {
      h4 {
        background-color: #fff;
        text-align: left;
      }
    }
  }

  &__search {
    padding: 20px 30px 10px;
  }
  .input-group {
    padding: 0 30px;
  }
  &__dropdowns-selection {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    .default-dropdown {
      width: 48%;
    }
  }
  .pagination {
    padding: 0 30px;
  }
}
