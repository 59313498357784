@import "../../styles/colors.scss";
.organization-card {
  display: flex;
  padding: 25px;

  &__separator {
    @include vertical-separator;
  }
  .logo-container {
    flex: 1;
    width: 200px;
    height: 200px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    [dir="ltr"] & {
      padding-right: 25px;
    }
    [dir="rtl"] & {
      padding-left: 25px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .info {
    flex: 2;
    [dir="ltr"] & {
      padding-left: 15px;
    }
    [dir="rtl"] & {
      padding-right: 15px;
    }

    .title {
      font-weight: bold;
    }

    .description {
      padding-top: 20px;

      div {
        img {
          display: block;
          width: 100%;
          height: 100px;
        }
      }
    }
  }
}
