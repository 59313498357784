@import "../../styles/colors.scss";

.job-details {
  margin: 0 0 25px;
  &__horizontal-separator {
    @include horizontal-separator;
    margin: 0;
    height: 2px;
  }
}

@media (max-width: 1024px) {
  .job-details-brief {
    &__title {
      text-align: center;
    }
    &__description {
      align-items: center;
      flex-direction: column;
    }
    &__anchors {
      justify-content: center;
    }
  }
}

@media (max-width: 480px) {
  .job-details-brief {
    &__anchors {
      flex-direction: column;
      div {
        margin: 5px;
      }
    }
  }
}
