@import "../../styles/colors.scss";
@import "../../styles/pxtorem.scss";

.event-details-card {
  display: flex;
  padding: 25px;

  &__body {
    overflow: auto;
    &__company {
      a {
        color: $primary-color;
      }
    }

    &__control-wrapper {
      display: flex;
      align-items: center;

      &__icon {
        padding: 0 toRem(10px);
      }

      p {
        color: lightgray;
        font-size: 12px;
      }
    }
  }

  &__logo {
    display: flex;
    padding: 20px 0;
    align-items: flex-start;
    justify-content: center;
    div {
      width: 200px;
      height: 200px;
      img {
        max-width: 100%;
        background-size: contain;
        height: 100%;
      }
    }
  }

  &__separator {
    @include vertical-separator;
    width: toRem(3px);
    margin: 0 toRem(25px);
  }
}
