@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.company-department-container {
  &__title {
    padding: 15px 35px;
    font-size: 20px;
    background-color: #fbfbfb;
  }
}

.company-department-info {
  &__companies {
    transition: all 0.7s;

    .companies__inner-content {
      position: relative;
      padding: 15px 35px;

      &__company {
        display: flex;
        align-items: center;

        &__image {
          .company-img {
            width: 60px;
            height: 60px;
            object-fit: scale-down;
          }

          @media (min-width: $phone-lg) {
            [dir="ltr"] & {
              margin-right: 15px;
            }
            [dir="rtl"] & {
              margin-left: 15px;
            }
          }
        }

        &__name {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

  &__staff {
    transition: all 0.7s;

    &__inner-content {
      padding: 0 30px;
      .contact-links {
        align-items: center;
        display: flex;
        justify-content: space-between;
        svg {
          color: #ccc;
          g {
            fill: #ccc;
            polygon {
              fill: #ccc !important;
            }
          }
        }
      }

      .staff-image {
        width: 50px;
        height: 50px;
        object-fit: scale-down;
        border-radius: 50%;
        border: 2px solid $primary-color;
        background-color: #fff;
      }

      .staff-links {
        display: flex;

        button {
          span {
            svg {
              color: #818181;
            }
          }
        }
      }
    }
  }

  &__department {
    transition: all 0.7s;
    border-bottom: 5px solid #ccc;

    .department-inner-content {
      &__department-role {
        &__header {
          &__header-title {
            cursor: pointer;
            font-weight: bold;
            font-size: 20px;
          }

          &__header-crud-links {
            display: flex;
            align-items: center;

            button {
              color: #818181;
            }
          }

          padding: 15px 35px;
          background-color: #f6f6f6;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__vacancies {
          &__vacancy {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 35px 15px 50px;

            &__title {
            }

            &__links {
              display: flex;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $phone-lg) {
  .company-department-info {
    &__companies {
      .companies__inner-content {
        &__company {
          flex-direction: column;
        }
      }
    }
  }
}
