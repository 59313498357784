@import "../../styles/colors.scss";

.comp-org-contacts-contact {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }

  &__upper {
    display: flex;
    padding: 25px 40px;
    justify-content: space-between;

    &__data {
      overflow: hidden;
      max-width: 220px;
      [dir="ltr"] & {
        margin: 0 10px 10px 0;
      }
      [dir="rtl"] & {
        margin: 0 0 10px 10px;
      }

      display: flex;
      align-items: flex-start;
      flex: 1;
    }

    &__btn {
      align-self: center;
    }
  }

  &__icon {
    background-color: $primary-color;
    border-radius: 50%;
    padding: 8px 10px 5px;
    align-self: baseline;
    [dir="ltr"] & {
      margin: 14px 12px 0 0;
    }
    [dir="rtl"] & {
      margin: 14px 0 0 12px;
    }
    svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
}

@media (max-width: 1024px) {
  .comp-org-contacts-contact {
    &__upper {
      flex-direction: column;
    }

    &__lower {
      flex-direction: column;
    }
  }
}
