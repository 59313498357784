.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);

  img {
    width: 50px;
    &:hover {
      z-index: 1;
    }
  }
}
