.comp-org-activity-exhibitions {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }

  .table-content {
    padding: 0 20px;
  }

  &__no-events {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
}

@media (max-width: 1024px) {
  .comp-org-activity-exhibitions {
    overflow-x: scroll;
  }
}
