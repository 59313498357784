@import "~/styles/colors.scss";

.input-group {
  display: flex;
  flex-direction: column;

  &__label {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__input {
    border: 1px solid lightgray;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    padding: 14px 17px;
    outline-color: $primary-color;

    &:disabled {
      background-color: #f1f1f1;
    }

    &--required {
      color: red;
      font-size: 12px;
      [dir="ltr"] & {
        margin-left: 12px;
      }
      [dir="rtl"] & {
        margin-right: 12px;
      }
    }
  }
}
