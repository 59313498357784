.profile-basic-info {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  h2 {
    font-weight: 500;
    margin-bottom: 0;
  }

  &__label {
    font-weight: 500;
    display: inline-block;
    margin: 15px 0;
  }

  &__input-container {
    display: flex;
    justify-content: space-between;

    &__input {
      box-sizing: border-box;
      padding-left: 10px;
      height: 38px;
      width: 100%;
    }

    //Phone Number Selector

    &__select {
      &__control {
        border-radius: 0 !important;
        border-right: none !important;
      }
      &__single-value {
        font-size: 14px;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }

  &__country-select {
    &__control {
      border-radius: 0 !important;
    }
    &__indicators {
      flex: 0.2 !important;
    }

    &__indicator-separator {
      display: none;
    }
  }
}
