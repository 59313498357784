@import "~/styles/variables.scss";

.social-links-group {
  &__header {
    .header-title {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: $weight-fat;
    }
    .header-subtitle {
      margin: 12px 0px 20px;
      display: block;
      color: #999999;
    }
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    .social-item {
      background-color: #fff;
      margin-bottom: 14px;
      display: flex;
      height: 32px;
      align-items: center;
      border: 1px solid lightgray;
      margin-right: 3%;
      &__icon {
        padding: 0 10px;
        cursor: pointer;
      }
      &__separator {
        border-left: 1px solid lightgray;
        height: 32px;
        padding-right: 10px;
      }

      &__input {
        width: 100%;
        border: none;
        box-sizing: border-box;
        padding-right: 10px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
