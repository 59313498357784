@import "../../styles/colors.scss";
.product-description-documents {
  h3 {
    @include h3GrayBackground;
  }

  &__documents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      padding: 10px;
    }
  }
}
