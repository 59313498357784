.company-organization-card {
  padding: 20px 0;

  &__body {
    display: flex;
    border-bottom: 1px solid lightgray;

    &__img-container {
      height: 200px;
      width: 200px;
      padding: 20px;

      &__img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }

    &__description {
      flex: 3;

      &__body-text {
        img {
          width: 100px;
          height: 100px;
          background-size: contain;
          // display: block;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    &__actions-container {
      &__action {
        display: flex;
        align-items: center;

        & > p {
          max-width: 210px;
          word-wrap: break-word;
          overflow: hidden;
          padding: 0 5px;
        }
      }
    }

    &__button {
      align-self: center;
    }
  }
}

@media (max-width: 1024px) {
  .company-organization-card {
    &__footer {
      &__actions-container {
        &__action {
          & > p {
            max-width: none;
          }
        }
      }
    }
  }
}
