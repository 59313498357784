.my-business-header-bar {
  padding: 15px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  &__text {
    font-size: 20px;
  }
}
