.default-input-time {
  display: flex;
  flex-direction: column;
  > input,
  label {
    margin-bottom: 15px;
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
