@import "../../../styles/colors.scss";
@import "../../../styles/variables.scss";

.my-business-resume-edit {
  padding: 14px 16px;
  &__header {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }
  &__section-header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__section {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    .section-bonus-information {
      color: #d3d3d3;
      font-size: 12px;
    }

    > div {
      width: 100%;
    }
  }
  &__section-remove {
    display: flex;
    justify-content: flex-end;
    svg {
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        color: $primary-color;
      }
    }
  }
  &__add-new-field {
    display: flex;
    justify-content: center;
    .add-btn {
      cursor: pointer;
      [dir="ltr"] & {
        margin-left: 10px;
      }
      [dir="rtl"] & {
        margin-right: 10px;
      }
    }
  }
  &__footer-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    .action-button {
      width: 25%;
      margin: 7px;
      button {
        width: 100%;
      }
    }
  }
}

@media (min-width: $desktop-sm) {
  .my-business-resume-edit {
    &__section {
      > div + div {
        [dir="ltr"] & {
          margin-left: 15px;
        }
        [dir="rtl"] & {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: $desktop-sm) {
  .my-business-resume-edit {
    &__section {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
