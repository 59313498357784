.default-textarea {
  &__label {
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__required {
    color: red;
    font-size: 12px;
    margin-left: 12px;
  }

  &__textarea {
    padding: 10px 12px;
    width: 100%;
    min-height: 100px;
    resize: none;
    border: 1px solid lightgray;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }
}
