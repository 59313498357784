@import "../../styles/colors.scss";

.product {
  & > .product-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;

    .product-swith-btn {
      padding: 0 12px;
    }

    .product-bold {
      font-weight: bold;
    }
  }

  .product-cards {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    box-shadow: $box-shadow-color;
    padding: 20px;
  }
}
