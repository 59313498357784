@import "../../styles/colors.scss";

.product-description {
  &__horizontal-separator {
    @include horizontal-separator;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    padding: 0 25px;

    .nav-link {
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $primary-color;
      }

      &__separator {
        border-right: 1px solid lightgray;
      }
    }

    .nav-link-selected {
      color: $primary-color;
    }
  }

  .nav-additional {
    justify-content: center;
    margin: 10px;
    a {
      margin: 0px 25px;
    }
  }
  &__additional-tab {
    h3 {
      font-weight: normal;
      text-align: center;
      background-color: #fbfbfb;
      margin: 0;
      padding: 15px;
      word-wrap: break-word;
    }
  }
  &__additional-content {
    padding: 0 30px;
    p {
      word-wrap: break-word;
      img {
        display: block;
        width: 100%;
        height: auto;
        float: none !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-body {
    &__content {
      .product-description-card {
        flex-direction: column;
        align-items: center;
        &__description__control {
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .main-body {
    &__content {
      .product-description-card {
        &__description__title {
          text-align: center;
        }
        flex-direction: column;
        align-items: center;
        &__description__price {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &__description__control {
          flex-direction: column;
          div {
            margin-bottom: 5px;
          }
        }
        &__description__about {
          text-align: center;
        }
        &__description__company {
          text-align: center;
        }
      }
    }
  }
}
