@import "../../../styles/colors.scss";

.statistics {
  padding: 15px 0;
  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .statistic-card {
      max-width: 180px;
      width: 100%;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid #eaeaea;
      margin: 5px;
      line-height: 1.4;
      &__header {
        display: flex;
        margin-bottom: 10px;
        .header-icon {
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .header-name {
          text-transform: uppercase;
          font-weight: 700;
          [dir="ltr"] & {
            padding-left: 5px;
          }
          [dir="rtl"] & {
            padding-right: 5px;
          }
        }
      }
      &__field {
        .field-title {
          font-weight: 700;
        }
        .field-data {
          display: flex;
          &__unique {
            color: $primary-color;
          }
          > div {
            &:first-child {
              [dir="ltr"] & {
                padding-right: 10px;
              }
              [dir="rtl"] & {
                padding-left: 10px;
              }
            }
            &:last-child {
              [dir="ltr"] & {
                padding-left: 10px;
                border-left: 2px solid #000;
              }
              [dir="rtl"] & {
                padding-right: 10px;
                border-right: 2px solid #000;
              }
            }
          }
        }
      }
    }
  }
  &__table {
    .stats-table-data {
      &__name {
        text-transform: uppercase;
        font-weight: 700;
      }
      &__unique-clicks {
        color: $primary-color;
      }
    }
  }
}
