.gjs-translation-manager-button {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 9px 44px;
  border: none;
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.gjs-translation-manager-modal {
  &__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__general {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;

    & button {
      font-size: 18px;
      font-weight: 800;
      padding: 7px 20px;

      & + button {
        margin-left: 5px;
      }

      &:disabled {
        background: rgba(0, 0, 0, 0.28);
        color: rgba(256, 256, 256, 0.3);
        cursor: not-allowed;
      }
    }
  }

  &__group {
    width: 32%;

    &--button {
      flex: 1;
      align-self: flex-end;
      text-align: right;
      padding: 1rem 0 !important;
    }
  }

  &__field {
    width: 100%;
  }

  &__button {
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 9px 44px;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
}

.gjs-translation-manager-list {
  display: flex;
  flex-flow: column nowrap;
  height: 400px;
  overflow-y: scroll;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.5rem 0;
    border-radius: 2px;

    &-text {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      font-size: 13px;
      margin: 0 12px 0 0;
      padding: 4px 0.5rem;
      border-radius: 2px;
      transition: all 300ms ease;
      cursor: pointer;

      &:hover,
      &:hover + & {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    &-capture {
      color: hsla(256, 256%, 100%, 0.6);
      font-size: 10px;
      font-weight: 600;
      font-family: monospace;
      margin: 0 0 0 auto;
      letter-spacing: 0.6px;
    }
  }
}

.gjs-mdl-content.gjs-mdl-content--preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  z-index: 99;
}
