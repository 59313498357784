@import "../../styles/colors.scss";

.filter-accordion-item {
  @include custom-scroll-bar;

  &__body {
    &__heading {
      &__button {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        color: #444;
        cursor: pointer;
        padding: 12px 32px;
        text-align: left;
        border: none;

        &:focus {
          outline: none;
        }

        &__filter-items {
          display: flex;
          flex-wrap: wrap;
          padding: 0 10px;
        }

        &__icon {
          [dir="ltr"] & {
            margin-left: auto;
          }
          [dir="rtl"] & {
            margin-right: auto;
          }
        }
      }
    }
  }
  &__panel {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 32px;
    &__input-container {
      position: relative;
      &__input {
        border-radius: 5px;
        border: 1px solid lightgray;
        margin: 14px 0;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 8px;

        &:focus {
          outline: none;
        }
      }

      &__icon {
        position: absolute;
        fill: lightgray;
        top: 21px;
        [dir="ltr"] & {
          right: 8px;
        }
        [dir="rtl"] & {
          right: calc(100% - 30px);
        }
      }
    }

    &__list {
      overflow: auto;
      max-height: 220px;
      &__item {
        cursor: pointer;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }
      }

      &__subitem {
        margin: 0 5px 5px 20px;
      }
    }
  }
}

.filter-accordion-item + .filter-accordion-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
