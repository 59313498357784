.login-modal {
  // MODAL BODY
  &__header {
    padding: 16px 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 500;
    }
  }
  // MODAL BODY
  &__body {
    padding: 0 32px 20px;
    display: flex;
    justify-content: space-between;
    &-column {
      flex: 1;

      ul {
        list-style-type: none;
        display: flex;
        padding: 0;

        li {
          padding-right: 10px;
        }
      }

      input {
        width: 91%;
        border-radius: 5px;
        border: 1px solid lightgrey;
        padding: 10px 12px;
      }

      &__social-media {
        padding: 5px 5px 3px 5px;
        border: 1px solid lightgray;
        border-radius: 5px;

        & > svg {
          width: 25px;
          height: 25px;
        }
      }

      &__agreement {
        & > a {
          color: #ff8700;
          text-decoration: underline;
        }
      }

      &__signin-forgot-pass {
        font-size: 14px;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        label > input {
          width: 20px;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      padding: 25px 0;
      text-decoration: underline;

      &:hover {
        color: #ff8700;
      }
    }
  }
}
