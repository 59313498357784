@import "../../styles/colors.scss";

.my-business {
  &__product-sizes-files {
    table {
      .table-div-content {
        max-width: 200px;
        word-break: break-word;
      }
      tbody {
        tr {
          td {
            text-align: -webkit-center;
            button {
              display: block;
            }
          }
        }
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      > div {
        width: 48%;
      }
    }
    &__document {
      margin: 15px 0 5px;
      display: flex;
      justify-content: center;
      input {
        margin-left: 100px;
      }
    }
  }
  &__product-sizes {
    &__info {
      display: flex;
      justify-content: space-between;
      .input-group {
        width: 60%;
      }
    }
    &__colorpicker {
      width: 40%;
      > div {
        margin: 0 auto;
      }
    }
    & button {
      margin: 0 auto;
      transition: all 300ms ease;

      &:hover {
        background: transparent;
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
}

.rtl {
  &__document {
    margin: 15px 0 5px;
    display: flex;
    justify-content: center;
    input {
      margin-left: -100px;
    }
  }
}

@media (max-width: 1024px) {
  .rtl {
    .MuiTableCell-root {
      div,
      a,
      .MuiButton-root {
        // margin: 0 -50% 0 0 !important;
      }
    }
  }

  .my-business {
    &__product-sizes-files {
      table {
        .table-div-content {
          display: flex;
          justify-content: center;
          word-break: break-word;
        }
      }
      .MuiTableCell-root {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        padding: 5px;
        div,
        a,
        .MuiButton-root {
          margin: 0 0 0 50%;
        }
      }
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border-bottom: 1px solid #ccc;
      }
      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 30%;
      }
      td:before {
        position: absolute;
        top: 20px;
        left: 0px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      td:nth-of-type(1):before {
        content: "Name";
      }
      td:nth-of-type(2):before {
        content: "Content";
      }
      td:nth-of-type(3):before {
        content: "Visualize";
      }
      td:nth-of-type(4):before {
        content: "Remove";
      }
    }
  }
}
