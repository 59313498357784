@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.shipping-selector-data {
  padding-bottom: 10px;
}

.shipping-methods {
  &__header {
    display: flex;
    align-items: center;
    > div + div {
      margin-left: 10px;
    }
    .header-button {
      cursor: pointer;
      border: 1px solid #ccc;
      padding: 5px 20px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      transition: 0.3s;
    }
    .header-button-open {
      background-color: $primary-color;
      color: #fff;
    }
  }
  &__content {
    z-index: 100;
    transition: 0.3s ease-in-out;
    opacity: 0;
    display: none;
    margin: 5px 15px;
    left: 0;
    right: 0;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.4);
    padding: 10px;
    max-height: -webkit-fill-available;
    overflow-y: scroll;
  }

  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-open {
    opacity: 1;
    display: block;
  }
}

@media (max-width: $desktop-sm) {
  .shipping-methods {
    &__header {
      flex-direction: column;
    }
    &__content {
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border-bottom: 1px solid #ccc;
      }
      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 40%;
        img {
          margin: 0 auto;
        }
      }
      td:before {
        position: absolute;
        top: 40%;
        left: 5%;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      .contact-links {
        max-width: 150px;
        margin: 0 auto;
        svg {
          fill: #ccc;
          margin: 0 15px;
        }
      }
      .staff-links {
        justify-content: center;
      }

      td:nth-of-type(1):before {
        content: "";
      }
      td:nth-of-type(2):before {
        content: "Name";
      }
      td:nth-of-type(3):before {
        content: "Description";
      }
      td:nth-of-type(4):before {
        content: "Currency";
      }
      td:nth-of-type(5):before {
        content: "Free delivery from";
      }
      td:nth-of-type(6):before {
        content: "Flat fee";
      }
      td:nth-of-type(7):before {
        content: "Percentage fee";
      }
    }
  }
}
