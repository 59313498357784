/* Spinner fix */
body {
  overflow-x: hidden;
}

.builder-site-page {
  margin: 0 0 60px;
  .builder-site-card {
    &__main {
      .slick-slider {
        .slick-arrow {
          [dir="rtl"] & {
            transform: rotate(180deg);
          }
        }
      }
      &__select-wrapper {
        padding: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .builder-site-page {
    .builder-site-card {
      &__main {
        .builder-header {
          display: flex;
          flex-direction: column;
          > div {
            width: 100% !important;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .builder-site-page {
    .builder-themes {
      &__header {
        flex-direction: column;
        align-items: center;
      }
      &__basic-info {
        text-align: center;
      }
    }

    .builder-template {
      img {
        width: 70px;
        height: 70px;
        object-fit: scale-down;
      }
      h3 {
        font-size: 12px;
      }
    }
  }
}
