@media (max-width: 1024px) {
  .company-department-info__staff__inner-content {
    padding: 0;
    .MuiTableCell-root {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div,
      a,
      .MuiButton-root {
      }
    }
  }
  .company-department-info__staff__inner-content {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 40%;
      img {
        margin: 0 auto;
      }
    }
    td:before {
      position: absolute;
      top: 40%;
      left: 5%;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    .contact-links {
      max-width: 150px;
      margin: 0 auto;
      svg {
        fill: #ccc;
        margin: 0 15px;
      }
    }
    .staff-links {
      justify-content: center;
    }

    td:nth-of-type(1):before {
      content: "PHOTO";
    }
    td:nth-of-type(2):before {
      content: "NAME";
    }
    td:nth-of-type(3):before {
      content: "DEPARTMENT";
    }
    td:nth-of-type(4):before {
      content: "POSITION";
    }
    td:nth-of-type(5):before {
      content: "CONTACT";
    }
    td:nth-of-type(6):before {
      content: "STATUS";
    }
  }
}

@media (max-width: 480px) {
  .company-department-info__department {
    .department-inner-content {
      &__department-role {
        &__header {
          padding: 15px 15px 15px 20px;
          flex-direction: column;
          align-items: flex-start;
          &__header-title {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
