@import "~/styles/colors.scss";

.editor {
  display: flex;

  &__icon {
    background-color: $gray-background;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border: 1px solid $gray-text;

    a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $gray-background;
    }

    svg {
      fill: $gray-text;
    }
  }

  &__icon:first-child {
    [dir="ltr"] & {
      margin-right: 5px;
    }
    [dir="rtl"] & {
      margin-left: 5px;
    }
  }
}
