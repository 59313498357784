@import "../../styles/variables.scss";
.cart-products-by-company {
  border-top: 1px solid #bcbcbc;
  padding: 30px;
  &__header {
    display: flex;
    align-items: center;
    a {
      padding: 0 5px;
      .company-image {
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        @media (min-width: $desktop-sm) {
          [dir="ltr"] & {
            margin-right: 15px;
          }
          [dir="rtl"] & {
            margin-left: 15px;
          }
        }
      }
      .company-name {
        font-size: 18px;
      }
    }
  }
  &__table-products {
    .product-table-details {
      a {
        display: flex;
        align-items: center;
      }
      &__image {
        height: 100px;
        width: 100px;
        object-fit: scale-down;
      }
      &__description {
        @media (min-width: $desktop-sm) {
          [dir="ltr"] & {
            padding-left: 20px;
          }
          [dir="rtl"] & {
            padding-right: 20px;
          }
        }
        .description-category {
          font-size: 0.75rem;
          color: #919191;
        }
      }
      &__options {
        line-height: 1.6;
        .options-header {
          font-size: 16px;
          font-weight: 700;
        }
        .options-params {
          color: #919191;
        }
      }
      &__unit-price {
        font-weight: 700;
      }
      &__total-cost {
        font-weight: 700;
        &_old {
          text-decoration: line-through;
          font-size: 12px;
        }
        &_discount {
          background: #ff9a44;
          font-size: 14px;
          padding: 2px 4px;
        }
      }
    }
  }
  &__cart-totals {
    padding: 20px 0 5px;
    .cart-totals-sections {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      > div + div {
        @media (min-width: $desktop-sm) {
          [dir="ltr"] & {
            margin-left: 30px;
          }
          [dir="rtr"] & {
            margin-right: 30px;
          }
        }
      }
      .cart-info-subdiv {
        width: 100%;
        line-height: 1.6;
        font-size: 14px;

        &__title {
          font-weight: 700;
          margin-bottom: 10px;
        }
        &__cart-details {
          display: flex;
          justify-content: space-between;
        }
        > div {
          width: 100%;
        }
      }
    }
  }
  &__footer-totals {
    display: flex;
    justify-content: flex-end;
    .footer-totals-inner {
      @media (min-width: $desktop-sm) {
        width: 30%;
      }
      font-weight: 700;
      line-height: 1.6;
      .cart-info-subdiv {
        &__cart-details {
          display: flex;
          justify-content: space-between;
        }
      }
      a {
        button {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: $desktop-sm) {
  .cart-products-by-company {
    &__table-products {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      td > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border-bottom: 1px solid #ccc;
      }
      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 40%;
        img {
          margin: 0 auto;
        }
      }
      td:before {
        position: absolute;
        top: 40%;
        left: 5%;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      td:nth-of-type(1):before {
        content: "DETAILS";
      }
      td:nth-of-type(2):before {
        content: "UNIT PRICE";
      }
      td:nth-of-type(3):before {
        content: "QUANTITY";
      }
      td:nth-of-type(4):before {
        content: "TOTAL";
      }
      td:nth-of-type(5):before {
        content: "";
      }
    }
    &__cart-totals {
      .cart-totals-sections {
        flex-direction: column;
      }
    }
  }
}
