@import "../../styles/pxtorem.scss";
@import "../../styles/variables.scss";

.quantity-input {
  input {
    text-align: center;
    padding: toRem(12px);
    width: 18px;
    border: 1px solid rgb(221, 221, 221);
  }
  button {
    border: 2px solid rgb(221, 221, 221);
    cursor: pointer;
  }

  div {
    @media only screen and (min-width: $tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (min-width: $tablet) {
    display: flex;
  }
}
