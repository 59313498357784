.comp-org-structure-tab {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }
  .structure-user_logo {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
  }

  &__table-content {
    padding: 0 20px;
  }

  .comp-org-structure-tab {
    &__structure {
      padding: 0 20px;
      .table-structure {
        &__company {
          display: flex;
          align-items: center;
          .image {
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
          }
          .name {
            padding-left: 15px;
          }
        }
      }

      &__no-data {
        text-align: center;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .comp-org-structure-tab {
    overflow-x: scroll;
  }
}
