@import "../../../styles/colors.scss";

.job-details-body {
  &__title {
    @include h3GrayBackground;
  }

  &__text {
    margin: 20px;
  }
}
