@import "../../styles/colors.scss";

.event-main {
  &__title {
    @include h3GrayBackground;
  }

  &__description {
    margin: 30px;
  }
}
