@import "../../styles/colors.scss";

.product-with-category {
  position: relative;

  .MuiPaper-root {
    box-shadow: $box-shadow-color;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    position: relative;
    background-color: #fff;
  }

  &__product-details {
    padding: 0px;
    > div {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__name {
    position: relative;
    font-size: 12px;
    text-align: center;
    margin: 10px 0 0;
    height: 40px;
    overflow: hidden;
    padding: 0 2px;

    &:after {
      background-color: rgba(255, 255, 255, 0.9);
      content: "";
      width: 100%;
      height: 4px;
      display: block;
      position: absolute;
      bottom: 0;
      -webkit-box-shadow: 0 0 14px 4px #fff;
      -moz-box-shadow: 0 0 14px 4px #fff;
      box-shadow: 0 0 14px 4px #fff;
    }
  }

  &__description {
    display: none;
    font-weight: 700;
    height: 60px;
  }

  &__price {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    color: $default-black;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    padding: 15px 0;
  }

  &__discount {
    background-color: $primary-color-darker;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    border-radius: 3px;
    margin-left: 10px;
    padding: 3px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    background: $primary-color-darker;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding: 6px 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
