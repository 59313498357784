.send-resume-container {
  margin-bottom: 30px;

  &__title {
    margin: 5px 0;
    text-align: center;
    font-weight: 500;
  }

  &__resume-content {
    padding: 0 10px;
    display: flex;
    justify-content: space-around;

    .resume-content {
      &__path {
        max-width: 50%;

        &-position {
          &-text {
            font-weight: 300;
          }
        }

        &-cvs {
          margin-bottom: 20px;

          &-text {
            font-weight: 300;
          }
        }
      }

      &__add-resume {
        &-new {
          font-weight: 300;
        }

        &-button {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
