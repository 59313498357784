.img-card {
  &__editor {
    position: absolute;
    bottom: 0;
  }

  &--regular {
    padding-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .img-card-image {
      box-sizing: border-box;
      width: 200px;
      height: 200px;

      img {
        height: 200px;
        width: 200px;
        object-fit: contain;
      }
    }

    .img-card-body {
      padding-left: 15px;

      .img-card-name {
        font-weight: bold;
        padding: 20px 0;
      }

      .img-card-date {
        display: none;
      }

      .img-card-price {
        display: none;
      }
    }
  }

  &--column {
    padding-right: 10px;
    display: flex;
    flex: 32%;
    flex-direction: column;

    .img-card-image {
      width: auto;
      height: 250px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .img-card-body {
      .img-card-name {
        display: none;
      }

      .img-card-text {
        padding-top: 10px;
      }

      .img-card-date {
        display: flex;
        align-items: center;

        .img-card-num-date {
          padding: 0 10px;
        }
      }

      .img-card-price {
        display: none;
      }
    }
  }

  &--products {
    position: relative;
    line-height: 1.3;
    width: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 40px;

    p {
      margin: 0;
    }

    .img-card-image {
      background-color: #fafafa;
      width: 200px;
      height: 200px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .img-card-body {
      .img-card-name {
        font-size: 12px;
        color: grey;
      }

      .img-card-text {
        // padding-top: 10px;
        font-weight: bold;
      }

      .img-card-date {
        display: none;
      }

      .img-card-price {
        color: #ff8700;
      }
    }
  }

  &--products-event {
    width: 100px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .img-card-image {
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .img-card-body {
      .img-card-name {
        font-size: 12px;
        color: grey;
      }

      .img-card-text {
        padding-top: 10px;
        font-weight: bold;
      }

      .img-card-date {
        display: none;
      }

      .img-card-price {
        color: #ff8700;
      }
    }
  }
}

.imgcard-discounts {
  position: absolute;
  z-index: 10;
  margin-top: 1rem;

  &__discount-percent,
  &__coupon-discount-percent {
    background-color: #d99a29;
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    padding: 2px 6px;
    border-radius: 3px;
    box-sizing: border-box;
  }
}
