.comp-org-files-tab {
  padding: 0 20px;

  &__no-files {
    display: block;
    font-weight: 400;
    font-size: 24px;
    padding: 100px;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-weight: normal;
  }

  &__flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__photo-box {
    width: 200px;
    max-height: 200px;
    margin: 15px 0;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__photo {
    width: 100%;
    cursor: pointer;
  }

  &__video-container {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
  }

  &__video {
    display: block;
    width: 80%;
  }

  &__documents-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__documents {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        padding: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .comp-org-files-tab {
    &__video-container {
      &__video {
        img {
          width: 100%;
        }
      }
    }
  }
}
