@import "../../styles/colors.scss";

.event-contacts {
  &__contacts {
    h3 {
      @include h3GrayBackground;
    }

    &__upper {
      display: flex;
      padding: 25px 40px;

      &__data {
        display: flex;
        align-items: flex-start;
        flex: 1;
      }

      &__btn {
        align-self: center;
      }
    }

    &__icon {
      background-color: $primary-color;
      border-radius: 50%;
      padding: 8px 10px 5px;
      align-self: baseline;
      margin: 14px 12px 0 0;
    }
  }

  &__bank {
    h3 {
      @include h3GrayBackground;
    }

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid lightgray;
      margin: 1em 0;
      padding: 0;
    }

    &__upper {
      padding: 0 40px;
    }
    &__lower {
      padding: 0 40px;
    }
  }

  &__map {
    h3 {
      @include h3GrayBackground;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 1024px) {
  .event-contacts {
    &__contacts {
      &__upper {
        flex-direction: column;
      }
      &__lower {
        flex-direction: column;
      }
    }
  }
}
