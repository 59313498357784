@import "../../../styles/colors.scss";
@import "../../../styles/variables.scss";

.wall-section {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-shadow: $box-shadow-color;
  padding: 20px;
}
