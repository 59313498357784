@import "../../styles/variables.scss";

.section-text-header {
  font-size: 1.5em;
  font-weight: $weight-fat;
}

@media (max-width: $phone-lg) {
  .section-text-header {
    margin-bottom: 10px;
  }
}
