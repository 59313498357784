.profile-business-cards {
  h2 {
    font-weight: 500;
    margin-bottom: 0;
  }
  p {
    font-weight: 500;
    text-transform: uppercase;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__card {
      width: 220px;
      height: 140px;
      border: 1px solid lightgray;
      margin-bottom: 20px;
      position: relative;

      &__hover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f3e0ca;
        opacity: 50%;
      }

      &__btn {
        position: absolute;
        top: 50px;
        left: 80px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .profile-business-cards {
    &__container {
      justify-content: space-around;
    }
  }
}
