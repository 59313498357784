@import "../../styles/colors.scss";

.input-group {
  display: flex;
  flex-direction: column;

  &__label {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.dynamic-fields {
  display: flex;
  flex-direction: column;

  &__title {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__group {
    display: flex;
    margin: 0 0 20px;
    align-items: center;
  }

  &__input-remove {
    width: 16px;
    height: 16px;
    display: block;
    cursor: pointer;
  }

  &__input {
    width: 100%;
    margin: 0 15px 0 0;
    border: 1px solid lightgray;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    padding: 14px 17px;
    outline-color: $primary-color;

    &:disabled {
      background-color: #f1f1f1;
    }

    &--required {
      color: red;
      font-size: 12px;
      [dir="ltr"] & {
        margin-left: 12px;
      }
      [dir="rtl"] & {
        margin-right: 12px;
      }
    }
  }
}
