.editor-group {
  display: flex;
  flex-direction: column;

  &__required {
    color: red;
    font-size: 12px;
    [dir="ltr"] & {
      margin-left: 12px;
    }
    [dir="rtl"] & {
      margin-right: 12px;
    }
  }

  &__label {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__input {
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 17px;
  }
}
