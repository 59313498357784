@import "../../../styles/colors.scss";

.wall-customization {
  position: relative;
  top: 2px;

  // & > .trigger {
  //   position: absolute;
  //   right: 0;
  //   top: -18px;
  //   height: 38px;
  // }

  &__button {
    background: transparent;
    border: none;
  }

  &-tooltip {
    padding: 0 20px;

    &__list {
      list-style: none;
      padding-inline-start: 0;

      &-item {
        & + .wall-customization-tooltip__list-item {
          padding: 6px 0 0;
        }

        & .default-checkbox-container__title {
          font-size: 15px;
          padding: 0 6px;
        }

        & .styled-checkbox + label {
          &:before {
            width: 20px;
            height: 20px;
            box-sizing: border-box;
          }

          &:after {
            left: 5px;
            top: 9px;
            transform: rotate(45deg) scale(0.5);
          }
        }
      }
    }
  }
}
