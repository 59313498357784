@import "../../../styles/variables.scss";

.my-business-delivery-edit {
  padding: 20px 30px;
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    > div {
      width: 100%;
    }
  }
  &__delivery-check {
    margin-bottom: 10px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__footer-column {
    width: 25%;
    margin: 7px;
  }
}

@media (min-width: $desktop-sm) {
  .my-business-delivery-edit {
    &__row {
      > div + div {
        [dir="ltr"] & {
          margin-left: 15px;
        }
        [dir="rtl"] & {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: $desktop-sm) {
  .my-business-delivery-edit {
    &__row {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
