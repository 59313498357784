@import "../../styles/colors.scss";

.product-description-about {
  h3 {
    @include h3GrayBackground;
    padding: 25px 0;
  }
  &__about {
    padding: 15px 20px;
    p {
      img {
        max-width: 100%;
      }
    }
  }
}
