.b24title {
  display: flex;
  align-items: center;
}

.b24type {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.b24name {
  margin-right: 0px;
  font-size: 36px;
  margin-left: 25px;
  cursor: pointer;
}

@media screen and (max-width: 627px) {
  .b24title {
    flex-wrap: wrap;
    justify-content: center;
  }
  .signB24 {
    margin-bottom: 16px;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .b24title {
    flex-wrap: wrap;
    justify-content: center;
  }
  .signB24 {
    margin-bottom: 16px;
    margin-left: 0 !important;
  }
}

.signB24 {
  display: flex;
  margin-left: 25px;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
  padding: 10px 20px;
  background-color: #fff;
  transition: background-color 300ms;
  border-radius: 100px;
  & img {
    height: 30px;
    margin-right: 10px;
  }
  &:hover {
    background-color: #ff9a44;
  }
}

.chat-bot {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  & .title-features {
    & p {
      text-align: center;
      margin: 0;
    }
  }
}

@media screen and (max-width: 493px) {
  .b24-features {
    & h4,
    & p {
      padding-right: 0 !important;
    }
  }
}

.b24-features {
  display: flex;
  flex-flow: row wrap;
  align-content: center;

  & img {
    height: 70px;
    width: 50px;
    object-fit: scale-down;
  }

  & h4,
  & p {
    padding-right: 20px;
  }

  & h4 {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 22px;
  }

  & p {
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .b24-feature {
    max-width: calc(100% / 3);
  }
}

.b24-feature {
  flex: 1 0 33%;
  min-width: 200px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  text-align: left;
  padding-top: 30px;

  &__image {
    height: 130px;
    object-fit: scale-down;
  }

  &__title {
    color: #26272d;
    font-size: 30px;
    font-weight: 400;
    line-height: 52px;
    margin: 0 0 22px;

    @media screen and (max-width: 992px) {
      & + & {
        margin: 0 0 8px;
      }
    }
  }

  &__description {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.b24-connections {
  text-align: center;
  margin-top: 30px;
}

.b24-icons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
}

.b24-icon {
  object-fit: scale-down;
  height: 100%;
  padding: 10px;
  flex: 1 0 10%;
}
