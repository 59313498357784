@import "../../styles/colors.scss";

.profile-media {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: scale-down;
  }

  &__logo {
    border: 1px solid lightgray;
    margin-top: 30px;
    width: 210px;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }
  &__btn {
    width: 100%;
    padding-top: 15px;
  }
}
