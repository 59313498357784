@import "../../styles/colors.scss";

.event-visitors {
  &__table {
    .visitors-table {
      &__name {
        display: flex;
        align-items: center;
        .user-image,
        svg {
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid $primary-color;
        }
        .user-name {
          padding-left: 10px;
        }
      }
    }
  }
}
