@import "../../../styles/colors.scss";

.my-business-products {
  &__body {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 40px;

    &__cards {
      position: relative;
    }
  }

  &__pagination {
    padding: 0 20px;
  }
}
