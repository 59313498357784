.pelecard {
  &__btn-container {
    margin: 10px 0;
    width: 150px;
  }

  &__btn {
    color: #ff9a44;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #ff9a44;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Roboto;
    line-height: 1.75;
  }
}
