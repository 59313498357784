@import "~/styles/colors.scss";

.company-small-card {
  position: relative;
  display: flex;
  margin: 15px 0;
  // overflow: hidden;

  .paid__features {
    padding: 5px 20px 10px;
  }

  .pos_feature_icon {
    height: 200px;
    margin: 0 auto;
  }

  .pro_features_container {
    background: rgb(245, 245, 245);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .template_chooser {
    padding: 25px 0;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    &__item {
      .img_buttons {
        position: relative;
        bottom: 48px;
        top: -118px;
        left: 64px;
        opacity: 0;
        transition: all ease 0.3s;
      }
      .img {
        margin: 15px;
        width: 250px;
        height: 170px;
        box-shadow: $box-shadow-color;
        background-size: cover;
        transition: all ease 0.3s;
      }
      &:hover {
        .img {
          filter: brightness(50%);
        }
        .img_buttons {
          opacity: 1;
        }
      }
    }
  }
}

.builder-site {
  &__logo {
    display: block;
    max-width: 250px;
    margin: 0 0 20px;
  }
}

.template-chooser {
  &__title {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
