.product-switch {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #262626;
    font-size: 21px;
    margin: 17px 0;
  }

  &__bold {
    font-weight: 500;
  }
}
