@import "../../styles/colors.scss";

.builder-themes {
  display: block !important;

  &__radio-group {
    display: block !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    & svg {
      fill: $primary-color;
    }
  }

  &__basic-info {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__empty {
    padding: 16px 2px;
  }

  &__title {
    margin: 1em 0 0.3rem;
  }

  &__caption {
    color: $gray-text;
    font-size: 14px;
  }

  &__main {
    cursor: grab;

    & .slick-slider {
      & .slick-arrow {
        color: $gray-text;
        transition: all 300ms ease;

        & svg {
          height: 1.6rem;
          width: 1.6rem;
        }

        &.slick-next {
          right: -18px;
        }

        &:hover {
          color: $primary-color;
        }

        &::before {
          content: none;
        }
      }

      & .slick-slide > div > div {
        outline: none;
      }
    }
  }
}
