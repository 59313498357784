@import "../../styles/colors.scss";

.product-carousel-item {
  position: relative;

  .MuiPaper-root {
    box-shadow: none !important;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    position: relative;
    background-color: #fff;
  }

  &__product-details {
    padding: 0px;
    > div {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__name {
    display: block;
    font-size: 14px;
    margin: 14px 0 0;
  }

  &__description {
    display: none;
    font-weight: 700;
    height: 60px;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    color: $default-black;
    font-size: 12px;
    font-weight: 700;
    line-height: 26.87px;
    margin: 10px 0 0;
  }

  &__discount {
    width: 50px;
    height: 18px;
    background-color: rgba($color-red-light, 0.2);
    color: $color-red-light;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    border-radius: 3px;
  }
}
