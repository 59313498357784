@import "../../styles/colors.scss";

.comp-org-contacts-tab {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }

  &__upper {
    display: flex;
    padding: 25px 40px;

    &__data {
      display: flex;
      align-items: flex-start;
      flex: 1;
    }

    &__btn {
      align-self: center;
    }
  }

  &__icon {
    background-color: $primary-color;
    border-radius: 50%;
    padding: 8px 10px 5px;
    align-self: baseline;
    margin: 14px 12px 0 0;
  }
}
