.organization-order-bar {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
}

.deals-container {
  padding: 0 30px;
}

.orders {
  table {
    .MuiTableCell-root {
      padding: 16px 8px;
    }
  }
  .MuiTableBody-root {
    border-top: 4px solid #ccc;
    border-bottom: 4px solid #ccc;
  }
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__button {
      position: absolute;
      left: 20px;
    }
  }
  .order-details {
    display: flex;
    align-items: center;

    .order-img {
      width: 50px;
    }
  }
  .MuiTableCell-root {
    text-align: center;
  }
}
