/* Spinner fix */
body {
  overflow-x: hidden;
}

.login-page {
  margin: 0 0 60px;
  .login-card {
    &__body {
      &-column {
        padding-right: 45px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .login-page {
    .login-card {
      &__body {
        flex-direction: column;
        &-column {
          padding-right: 0;
        }
      }
    }
    .login-modal {
      &__body-column {
        &__signin-forgot-pass {
          justify-content: space-around;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .login-page {
    .login-card {
      &__body {
        padding: 0 10px 20px;
      }
    }
  }
}
