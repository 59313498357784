.company-document {
  width: 150px;
  height: 200px;
  border: 1px solid lightgrey;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin: 30px 0;

  &:not(:last-child) {
    margin-right: 30px;
  }

  display: flex;
  flex-wrap: wrap;

  &__anchor {
    position: relative;
    display: flex;
  }

  &__preview {
    position: absolute;

    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
  }

  &__gradient {
    transition: all 0.2s;
  }

  &__icon-box {
    width: 100%;
    height: 100%;
    position: absolute;

    svg {
      width: 25%;
      height: 25%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__name {
    z-index: 10;
    font-size: 15px;
    width: 100%;
    // min-height: 30%;
    padding: 15px 0;
    background-color: #000;
    color: #fff;
    align-self: flex-end;
    text-align: center;

    transition: all 0.2s;
  }

  &:hover &__gradient {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: 5;
    background-color: grey;
    opacity: 0.7;
  }
}
