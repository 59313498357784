.my-person-orders {
  h3 {
    font-weight: normal;
  }
  .order-details {
    display: flex;
    align-items: center;

    .order-img {
      width: 50px;
    }
  }
}

@media (max-width: 1024px) {
  .my-person-orders {
    .deals-container {
      .MuiTableCell-root {
        padding: 16px 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .my-person-orders {
    .deals-container {
      .MuiTableHead-root {
        .MuiTableRow-root {
          flex-direction: column;
          display: flex;
        }
        .MuiTableCell-root {
          padding: 16px 0;
          font-size: 12px;
        }
      }
      .MuiTableBody-root {
        .order-details {
          display: flex;
          flex-direction: column;
        }
        .MuiTableRow-root {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
