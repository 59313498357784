@import "../../styles/colors.scss";

.default-multi-range-select {
  position: relative;

  &__remove-selection {
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      color: #ccc;
    }
  }

  &__title {
    display: block;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__flex-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__icon {
    margin-right: 10px;
  }

  &__info {
    color: lightgray;
    font-size: 12px;
  }

  &__item {
    flex: 1 1 0px;
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 5px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    &--active {
      border-color: $primary-color;
      background-color: $primary-color;
      color: #fff;
    }
  }

  &__input {
    width: 100%;

    &:focus {
      outline-color: $primary-color;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
