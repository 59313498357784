@import "../../styles/colors.scss";

.builder-site-card {
  &__return-icon {
    font-size: 30px !important;
    margin-top: 1px;
    transition: all 200ms ease !important;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }

  &__main {
    padding: 25px 32px !important;
  }

  &__footer {
    padding: 0 32px 32px !important;
  }

  &__input-wrapper {
    &.Mui-focused fieldset {
      border-color: $primary-color !important;
    }
  }

  &__input {
    padding: 12px 16px !important;
  }

  &__select-wrapper {
    &.Mui-focused fieldset {
      border-color: $primary-color !important;
    }

    & .MuiSelect-select {
      padding: 12px 16px !important;
    }
  }
}
