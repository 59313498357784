.input-rate-skill-container {
  .MuiSvgIcon-fontSizeLarge {
    font-size: 50px;
  }
  .MuiBox-root {
    padding: 0;
    margin: 0;
  }
  .MuiRating-root {
    color: #ff8800;
  }
}
