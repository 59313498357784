.gjs-theme-manager-button {
  top: 1px;
}

.gjs-theme-manager {
  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    &--between {
      justify-content: space-between;
    }
  }

  &__title {
    margin: 0;
  }

  &__form {
    display: flex;
    justify-content: space-between;
  }

  &__group {
    flex: 1;
    padding: 0 10px;
  }

  &__field {
    width: 100%;
  }

  &__input::-webkit-file-upload-button {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }

  &__input::-ms-browse {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }

  &__button {
    color: inherit;
    height: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 9px 18px;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }

    &--icon {
      height: 26px;
      font-size: 1.3em;
      padding: 0 0.54em;
    }

    &--space {
      margin: 0 0 0 4px;
    }

    &--rounded {
      border-radius: 50px;
    }

    &--on-hover {
      margin: 6px;
      padding: 5px 8px;
    }

    &--no-bg {
      background: none;
    }

    &--only-icon {
      position: relative;
      font-size: 1.35em;
      padding: 0 0.54em;
      height: 28px;

      & > i {
        position: relative;
        bottom: 1px;
        right: 1px;
      }
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.28);
      color: rgba(256, 256, 256, 0.3);
      cursor: not-allowed;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0 0;

    &--padding {
      padding: 0 10px 10px;
    }
  }

  .tagify {
    border: none;

    &__input {
      padding: 5px;
      margin: 0;
      overflow: hidden;

      &::before {
        color: inherit;
        font-size: inherit;
      }
    }

    &__tag {
      & > div {
        padding: 2px 5px;
        font-size: 12px;
      }

      &--editable .tagify__tag-text,
      &--editable .tagify__tag__removeBtn {
        color: #fff;
      }
    }

    &__dropdown {
      background: red;
    }
  }
}

.gjs-tm-theme {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }

  &__actions {
    display: flex;
    align-items: stretch;
  }

  &__action {
    margin: 0px 0 0 10px;
    cursor: pointer;
  }

  &__slider {
    position: relative;
    width: 90%;
    min-height: 160px;
    margin: 0 auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: inherit;
    outline: none;

    &::after {
      font-size: 2em;
    }
  }

  .swiper-button-prev {
    position: absolute;
    left: -6.4%;
  }

  .swiper-button-next {
    position: absolute;
    right: -6.4%;
  }
}

.gjs-tm-delimiter {
  display: inline-block;
  position: relative;
  left: -1.7%;
  width: 103.5%;
  height: 1px;
  background: hsla(0, 0%, 0%, 0.2);
  margin: 22px 0 0;
}

.gjs-tm-template {
  &__image {
    width: 100%;
    height: 140px;
    object-fit: scale-down;
  }

  &__actions {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease !important;

    & * {
      transition: none !important;
    }
  }

  &:hover > &__actions {
    display: flex;
    visibility: visible;
    background: hsla(0, 0%, 0%, 0.1);
    border-radius: 3px;
  }

  &__title {
    margin: 0;
  }
}

.gjs-mdl-content.gjs-mdl-content--preloader::before {
  content: "";
  background: hsla(0, 0%, 100%, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  z-index: 99;
}
