@import "~/styles/variables.scss";

.additional-pages {
  &__header {
    font-size: 18px;
    font-weight: $weight-fat;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
}
