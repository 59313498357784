@import "../../styles/colors.scss";

.popular-news {
  background: white;
  box-shadow: $box-shadow-color;
  padding: 0 20px 20px;
  border-radius: 5px;
  margin-bottom: 16px;

  .popular-news-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .popular-news-title {
      font-weight: bold;
    }
  }

  .popular-news-body {
    display: flex;

    & .img-card--column {
      & p.img-card-num-date {
        color: $gray-text;
        font-size: 15px;
        padding: 0 6px;
        margin: 8px 0 6px;
      }
    }
  }
}

@media (max-width: 992px) {
  .popular-news {
    .popular-news-body {
      display: block;
      .img-card--column {
        width: auto;
        .img-card-image {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .popular-news {
    .event-card {
      flex-direction: column;
    }
  }
}
