@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=cyrillic-ext");

.tox-notifications-container {
  display: none !important;
}

body {
  font-family: "Roboto";
  font-weight: normal;
  background: url(./assets/background.png) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #252525;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}
