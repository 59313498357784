@import "~/styles/colors.scss";

.default-date-picker {
  &__label {
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__required {
    color: red;
    font-size: 12px;
    [dir="ltr"] & {
      margin-left: 12px;
    }
    [dir="rtl"] & {
      margin-right: 12px;
    }
  }
}

.react-date-picker {
  width: 100%;

  &__wrapper {
    width: 100%;
    background-color: white;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid lightgray;
  }

  &__button {
    [dir="ltr"] & {
      margin-left: 12px;
    }
    [dir="rtl"] & {
      margin-right: 12px;
    }
  }
}

.react-calendar {
  &__tile--active:enabled:hover {
    background-color: $primary-color-darker;
  }

  &__tile--active:enabled:focus {
    background-color: $primary-color-darker;
  }
  &__tile--active {
    background-color: $primary-color;
  }

  &__tile--now {
    background-color: lightgray;
  }
}
