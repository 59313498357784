@import "../../styles/colors.scss";

.event {
  &__separator {
    @include horizontal-separator;
  }

  &__separator-margin-0 {
    margin-bottom: 0;
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 25px;

    &__link {
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $primary-color;
      }
    }

    &__selected {
      color: $primary-color;
    }

    &__separator {
      @include vertical-separator;
    }
    &__nav-additional {
      display: flex;
      justify-content: center;
      margin: 10px;
      a {
        margin: 0px 25px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .event {
    .event-details-card {
      flex-direction: column;
    }
    .event-main__description {
      p > img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .event {
    &__nav {
      align-items: center;
    }
  }
}
