.my-business-leads {
  .leads-table {
    overflow-x: auto;
  }
  &__header {
    border-bottom: 1px solid #ebebeb;
    padding: 8px 0 0;
  }

  &__title {
    color: #262626;
    font-size: 24px;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .leads-table {
    .MuiTableCell-root {
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      div,
      a,
      .MuiButton-root {
        margin: 0 0 0 50%;
      }
    }
  }
  .leads-table {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 30%;
    }
    td:before {
      position: absolute;
      top: 20px;
      left: 0px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    td:nth-of-type(1):before {
      content: "SRC";
    }
    td:nth-of-type(2):before {
      content: "MSG";
    }
    td:nth-of-type(3):before {
      content: "USER";
    }
    td:nth-of-type(4):before {
      content: "USERNAME";
    }
    td:nth-of-type(5):before {
      content: "EMAIL";
    }
    td:nth-of-type(6):before {
      content: "PHONE";
    }
    td:nth-of-type(7):before {
      content: "DATE";
    }
    td:nth-of-type(8):before {
      content: "EDIT";
    }
    td:nth-of-type(9):before {
      content: "REMOVE";
    }
  }
}
