$box-shadow-color: 0 0 24px rgba(43, 43, 43, 0.1);
$primary-color: #ff9a44;
$primary-color-darker: #ff7f13;
$primary-danger-red: #ce3131;
$color-red-light: #ff5a5a;
$gray-background: #fafafa;
$gray-background-darker: #eaebed;
$gray-text: #999999;
$grey-active: #ededed;
$gray-separator: #eaeaea;
$facebook: #3b5998;
$linkedin: #0e76a8;
$twitter: #1da1f2;
$default-black: #262626;

//Business Card Palette
$color-white: #fff;
$color-black: #000;

$color-gray-light: #f1f1f1;
$color-gray: #a0a0a0;
$color-gray-dark: #85847f;

$color-blue: #6979b8;

$color-green: #52ac62;

$color-orange: #ff8700;

$color-red: #d24a43;
$color-red-darked: #cc0000;
$color-green-light: #56a963;
$color-green-darker: #458a50;

$color-text-light: #252525;
$color-text-dark: #000000;
//Business Card Palette End

@mixin horizontal-separator {
  background-color: $gray-separator;
  margin: 10px 0;
  height: 1px;
}

@mixin vertical-separator {
  background-color: $gray-separator;
  margin: 0 10px;
  width: 1px;
}

@mixin h3GrayBackground {
  font-weight: normal;
  text-align: center;
  background-color: #fafafa;
  margin: 0;
  padding: 15px 0;
}

@mixin custom-scroll-bar {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $gray-background;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    height: 20px;
    border-radius: 3px;
    background: $primary-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color-darker;
  }
}

@mixin no-data {
  display: block;
  font-weight: 400;
  font-size: 24px;
  padding: 100px;
  text-align: center;
}
