@import "../../styles/colors.scss";

.newsletter {
  display: flex;
  background: #ff8700da;
  padding: 25px 35px;
  position: relative;
  box-shadow: $box-shadow-color;
  border-radius: 5px;
  overflow: hidden;

  .newsletter-form {
    width: auto;

    .newsletter-p {
      margin: 0;
      font-weight: bold;
      font-size: 30px;
      color: white;
    }
  }

  .social_links {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-top: 15px;
    img {
      height: 36px;
      margin-right: 5px;
    }
  }

  .newsletter-image {
    background-repeat: no-repeat;
    position: absolute;
    background: url(../../assets/newsletter-envelope.png);
    width: 200px;
    height: 264px;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

.rtl {
  .newsletter-form {
    .subscription-form {
      .MuiFormControl-root {
        .MuiInputLabel-formControl {
          right: 0;
          left: auto;
        }
      }
      .MuiFormControl-root:nth-child(2) {
        .MuiInputLabel-shrink {
          transform: translate(0, 1.5px) scale(0.95);
        }
      }
    }
  }
}
