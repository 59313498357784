@import "~/styles/colors.scss";

.forgot-password-card {
  // MODAL BODY
  &__header {
    padding: 16px 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 500;
    }
  }
  // MODAL BODY
  &__body {
    padding: 0 32px 20px;
    display: flex;
    justify-content: space-between;
    &-column {
      flex: 1;

      ul {
        list-style-type: none;
        display: flex;
        padding: 0;

        li {
          [dir="ltr"] & {
            padding-right: 10px;
          }
          [dir="rtl"] & {
            padding-left: 10px;
          }
        }
      }

      input {
        width: 100%;
        border-radius: 5px;
        border: 1px solid lightgrey;
        padding: 10px 12px;
        outline-color: $primary-color;
        margin: 0 0 20px;
        box-sizing: border-box;
      }
    }
  }

  &__footer {
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      padding: 25px 0;
      text-decoration: underline;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
