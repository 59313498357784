@import "src/styles/variables.scss";

@media (min-width: $desktop-sm) {
  .column-wrapper-lg {
    flex-basis: 70%;
    [dir="ltr"] & {
      padding-left: 18px;
    }
    [dir="rtl"] & {
      padding-right: 18px;
    }
  }
}
