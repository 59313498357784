.my-business-add-variation-products {
  padding: 14px 16px;
  &__wrapper {
    display: flex;
    .wrapper-column-sm {
      flex-basis: 30%;
    }
    .wrapper-column-lg {
      margin-left: 20px;
      flex-basis: 70%;
    }
    .column-section {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      > div + div {
        margin-left: 10px;
      }
      .input-group {
        width: 100%;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    .footer-column {
      width: 25%;
      margin: 7px;
      button {
        width: 100%;
      }
    }
  }
}
