@import "../../../styles/colors.scss";

@mixin uppercaseTitle {
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin infoText {
  color: lightgray;
  font-size: 12px;
}

.my-business-companies-edit {
  padding: 14px 16px;

  .my-business-category-picker {
    &__title {
      @include uppercaseTitle;
    }

    &__required {
      color: red;
      font-size: 12px;
      margin-left: 12px;
    }

    &__info {
      @include infoText;
    }

    &__flex-container {
      display: flex;
      justify-content: space-between;
    }

    &__input {
      margin-top: 5px;
    }

    &__flex-item {
      flex: 0.45;

      button:hover {
        border-color: $primary-color;
        color: $primary-color;
        background-color: transparent;
        transition: all 300ms ease;
      }
    }
  }
}
