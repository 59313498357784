/* We can remove the border we've set at the beginnig */
#gjs {
  border: none;
}

#gjs * {
  transition: all 300ms ease;
}

/* Primary color for the background */
.gjs-one-bg {
  background-color: #383f4a;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.62);
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: hsl(217, 14%, 25%);
  color: hsla(0, 0%, 100%, 0.9);
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  // color: #b66985;
  color: #ff9a44;
}

/* scrollbar */
#gjs * ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}

#gjs * ::-webkit-scrollbar-track {
  // background: #3d3d3d !important;
}

/* sections */
.gjs-category-title,
.gjs-sm-sector .gjs-sm-title,
.gjs-clm-tags .gjs-sm-title,
.gjs-block-category .gjs-title,
.gjs-layer-title {
  border-bottom: 3px solid hsla(0, 0%, 0%, 0.1);
}

#gjs-clm-add-tag,
.gjs-clm-tags-btn {
  background-color: rgba(255, 255, 255, 0.05);
}

.gjs-sm-sector .gjs-sm-field.gjs-sm-composite,
.gjs-clm-tags .gjs-sm-field.gjs-sm-composite,
.gjs-sm-sector .gjs-sm-composite.gjs-clm-field,
.gjs-clm-tags .gjs-sm-composite.gjs-clm-field,
.gjs-sm-sector .gjs-sm-composite.gjs-clm-select,
.gjs-clm-tags .gjs-sm-composite.gjs-clm-select {
  border: 1px solid hsla(0, 0%, 0%, 0.1);
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer.gjs-sm-active,
.gjs-clm-tags .gjs-sm-property .gjs-sm-layer.gjs-sm-active {
  background-color: rgba(255, 255, 255, 0.05);
}

/* customize selection and tooltip */
.gjs-comp-selected {
  outline: 3px solid rgba(43, 135, 218, 0.4) !important;
}

.gjs-toolbar {
  position: absolute;
  background-color: #2b87da;
  color: white;
  z-index: 10;
  margin-top: -3px;
  padding: 2px 6px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.05);

  & .gjs-toolbar-item {
    font-size: 14px;

    & svg {
      width: 15px;
      height: 15px;
    }

    & + .gjs-toolbar-item {
      margin-left: 5px;
    }

    &:hover {
    }
  }
}

/* Fix preloader */
.grapes-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  font-family: Helvetica, sans-serif;
  background: rgba(51, 51, 51, 0.9);
  background: rgba(55, 61, 73, 0.9);
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  display: block;
  font-size: 12px;
  left: 50%;
  line-height: normal;
  max-width: 32rem;
  opacity: 0;
  overflow: hidden;
  padding: 0.6rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: all 0.216s ease;
  z-index: 99;
}

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -0.5rem);
  -ms-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem);
}

[data-tooltip][disabled],
[data-tooltip].disabled {
  pointer-events: auto;
}

[data-tooltip-pos="right"]::after {
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos="right"]:focus::after,
[data-tooltip-pos="right"]:hover::after {
  -webkit-transform: translate(0.5rem, 50%);
  -ms-transform: translate(0.5rem, 50%);
  transform: translate(0.5rem, 50%);
}

[data-tooltip-pos="bottom"]::after {
  bottom: auto;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-tooltip-pos="bottom"]:focus::after,
[data-tooltip-pos="bottom"]:hover::after {
  -webkit-transform: translate(-50%, 0.5rem);
  -ms-transform: translate(-50%, 0.5rem);
  transform: translate(-50%, 0.5rem);
}

[data-tooltip-pos="left"]::after {
  bottom: 50%;
  left: auto;
  right: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos="left"]:focus::after,
[data-tooltip-pos="left"]:hover::after {
  -webkit-transform: translate(-0.5rem, 50%);
  -ms-transform: translate(-0.5rem, 50%);
  transform: translate(-0.5rem, 50%);
}

/* Fix code editor */
.CodeMirror-linenumbers {
  width: auto !important;
}

.CodeMirror-line {
  padding-left: 40px !important;
  padding-top: 1px !important;
}

/* Fix modal buttons */
.gjs-btn-import__custom-code,
.gjs-btn-import {
  margin-top: 6px;
}

/* Fix traits labels */
.gjs-trt-trait .gjs-label-wrp .gjs-label {
  word-break: break-word;
}

/* Fix flex */
.gjs-sm-sector.gjs-sm-sector__flex {
  overflow-x: hidden;
}

/* Fix select */
.gjs-field.gjs-field-class_select select option {
  background: #2d323b;
}

/* Preloader */
.gjs-cv-canvas.preloader {
  &::before {
    content: "\f110";
    position: absolute;
    top: 48%;
    left: 44%;
    color: #a09e9e;
    font-family: FontAwesome;
    font-size: 40px;
    animation: fa-spin 600ms infinite steps(8);
    z-index: 9999;
  }

  &::after {
    content: " ";
    position: absolute;
    // background: rgba(255, 255, 255, 0.64);
    background: rgba(56, 63, 74, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Fix RTE actions
.gjs-rte-actionbar .gjs-rte-action {
  font-size: 0.86rem;
}

// Hide b24chat widget
.grapes-application-wrapper div[id*="b24-widget"] {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none !important;
  opacity: 0 !important;
}

// Icons
.icon-add-comp::before,
.icon-comp100::before,
.icon-comp50::before,
.icon-comp30::before,
.icon-rm::before {
  content: "";
}
.icon-add-comp {
  background: url("./icons/icon-sq-a.png") no-repeat center;
}
.icon-comp100 {
  background: url("./icons/icon-sq-1.png") no-repeat center;
}
.icon-comp50 {
  background: url("./icons/icon-sq-2.png") no-repeat center;
}
.icon-comp30 {
  background: url("./icons/icon-sq-3.png") no-repeat center;
}
.icon-rm {
  background: url("./icons/icon-sq-r.png") no-repeat center;
}

.icons-flex {
  background-size: 70% 65% !important;
  height: 15px;
  width: 17px;
  opacity: 0.9;
}
.icon-dir-row {
  background: url("./icons/flex-dir-row.png") no-repeat center;
}
.icon-dir-row-rev {
  background: url("./icons/flex-dir-row-rev.png") no-repeat center;
}
.icon-dir-col {
  background: url("./icons/flex-dir-col.png") no-repeat center;
}
.icon-dir-col-rev {
  background: url("./icons/flex-dir-col-rev.png") no-repeat center;
}
.icon-just-start {
  background: url("./icons/flex-just-start.png") no-repeat center;
}
.icon-just-end {
  background: url("./icons/flex-just-end.png") no-repeat center;
}
.icon-just-sp-bet {
  background: url("./icons/flex-just-sp-bet.png") no-repeat center;
}
.icon-just-sp-ar {
  background: url("./icons/flex-just-sp-ar.png") no-repeat center;
}
.icon-just-sp-cent {
  background: url("./icons/flex-just-sp-cent.png") no-repeat center;
}
.icon-al-start {
  background: url("./icons/flex-al-start.png") no-repeat center;
}
.icon-al-end {
  background: url("./icons/flex-al-end.png") no-repeat center;
}
.icon-al-str {
  background: url("./icons/flex-al-str.png") no-repeat center;
}
.icon-al-center {
  background: url("./icons/flex-al-center.png") no-repeat center;
}

[data-tooltip]::after {
  background: rgba(51, 51, 51, 0.9);
}

.gjs-pn-commands {
  min-height: 40px;
}

//  .fa-cog {
//     display: none;
//  }

.gjs-am-preview-cont {
  height: 100px;
  width: 100%;
}

.gjs-logo-version {
  background-color: #756467;
}

.gjs-pn-panel.gjs-pn-views {
  padding: 0;
  border-bottom: none;
}

.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.gjs-pn-views .gjs-pn-btn {
  margin: 0;
  height: 40px;
  padding: 10px;
  width: 33.3333%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

.CodeMirror {
  min-height: 450px;
  margin-bottom: 8px;
}
.grp-handler-close {
  background-color: transparent;
  color: #ddd;
}

.grp-handler-cp-wrap {
  border-color: transparent;
}

.dropzone-inner {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 4px dashed #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.45);
}
