@import "../../styles/variables.scss";

.wall {
  padding-bottom: 0;

  & > &__wrapper > .tab:first-child {
    padding-top: 0;
  }

  &__wrapper {
    position: relative;

    & > .wall-customization {
      position: absolute;
      right: 0;
      top: 92px;
    }

    & .wall-newsletter {
      padding-top: 130px;
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  &__tab-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: bold;
    margin-left: 25px;
    text-transform: uppercase;
    cursor: pointer;
  }

  [dir="rtl"] & {
    &__wrapper {
      & > .wall-customization {
        left: 135px;
        right: auto;
      }
    }

    &__tab {
      & svg {
        transform: scaleX(-1);
      }
    }

    &__tab-title {
      margin-right: 25px;
    }

    & .popular-news-header {
      div {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    & .newsletter-image {
      left: 0;
      right: auto;
      transform: scaleX(-1);
    }

    @media (max-width: 1024px) {
      width: 100%;

      & .img-card--column {
        padding-right: 0;
      }
    }

    @media (max-width: 992px) {
      &__wrapper {
        & > .wall-customization {
          left: 122px;
        }

        & > .filter {
          width: 100%;
        }
      }

      .product-cards {
        .img-card--products {
          margin: 10px auto;

          .img-card-text {
            text-align: center;
          }
        }
      }

      .partners {
        .partner {
          margin: 10px auto;
        }
      }

      .wall-newsletter {
        .newsletter {
          .newsletter-image {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    &__wrapper {
      & > .wall-customization {
        top: 0;
        left: 0;
        margin: -20px 4% 0;
      }

      & > .filter {
        width: 100%;
        margin: 30px 0 0;
      }
    }
  }

  @media (min-width: $phone-lg) {
    &__tab {
      font-size: 28px;
    }
  }

  @media (max-width: $phone-lg) {
    &__tab-title {
      font-size: 24px;
    }
  }
}
