.organization-description {
  .title {
    font-size: 20px;
    text-align: center;
    margin: 30px 0;
  }

  .description {
    .image {
      float: right;
      margin: 20px;
      box-shadow: 10px 10px 5px 0px rgba(255, 231, 204, 1);
    }
  }
}
