@import "./styles/colors.scss";

.app-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  // min-width: 100vw;
  min-height: 100vh;

  .main-body {
    display: flex;
    max-width: 1600px;
    width: 95%;
    min-height: 70vh;

    &__content {
      margin-bottom: 40px;
      width: 100%;
    }

    &__navbar {
      min-width: 300px;
      padding-right: 24px;
    }
  }

  & .MuiPaper-elevation1 {
    box-shadow: $box-shadow-color;
  }

  & .MuiPaper-rounded {
    border-radius: 5px;
  }
}

.rtl {
  .main-body {
    &__navbar {
      padding-right: 0;
      padding-left: 24px;
    }
    &__content {
      .default-accordion {
        &__icon {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }

  .header {
    .upper-header {
      .icons {
        .language-switcher {
          .MuiInput-root {
            .MuiSelect-selectMenu.MuiSelect-selectMenu {
              color: #4f4f4f;
            }
          }
        }

        .icon {
          svg {
            g {
              path {
                fill: #4f4f4f;
              }

              g {
                path {
                  fill: #4f4f4f;
                }
              }

              polygon {
                fill: #4f4f4f !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .app-container {
    .main-body {
      padding: 0 1vw;
      box-sizing: border-box;
      // justify-content: space-between;
      // padding: 0 10px;

      &__navbar {
        // width: 25%;
      }

      &__content {
        // width: 75%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .app-container {
    .header {
      .upper-header {
        .options {
          justify-content: center;
        }

        .icons {
          .language-switcher {
            .MuiInput-root {
              .MuiSelect-selectMenu.MuiSelect-selectMenu {
                color: #4f4f4f;
              }
            }
          }

          .icon {
            svg {
              g {
                path {
                  fill: #4f4f4f;
                }

                g {
                  path {
                    fill: #4f4f4f;
                  }
                }

                polygon {
                  fill: #4f4f4f !important;
                }
              }
            }
          }
        }
      }
    }

    .main-body {
      &__navbar {
        width: auto;
      }

      &__content {
        width: 100%;

        .partners {
          justify-content: space-around;
        }
      }
    }

    .newsletter {
      .newsletter-image {
        display: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .app-container {
    .main-body {
      flex-direction: column;
      padding: 0 4vw;
      box-sizing: border-box;

      &__navbar {
        width: 70%;
        margin: 0 auto;
        padding-right: 0;
      }

      &__content {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .app-container {
    .main-body {
      &__navbar {
        width: 100%;
      }
    }
  }
}
