.comp-org-contacts-map {
  h3 {
    font-weight: normal;
    text-align: center;
    background-color: #fbfbfb;
    margin: 0;
    padding: 15px 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__google-maps-iframe {
    iframe {
      width: 100%;
      height: 450px;
    }
  }
}

@media (max-width: 767px) {
  .comp-org-contacts-map {
    div {
      img {
        width: 100%;
      }
    }
  }
}
