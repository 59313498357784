@import "~/styles/colors.scss";

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > p {
    [dir="ltr"] & {
      padding-right: 20px;
    }
    [dir="rtl"] & {
      padding-left: 20px;
    }
  }

  &__container {
    background-color: white;
    box-shadow: $box-shadow-color;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__page {
    [dir="ltr"] & {
      border-right: 1px solid lightgrey;
    }
    [dir="rtl"] & {
      border-left: 1px solid lightgrey;
    }
    padding: 10px 0;
  }

  &__active {
    background-color: $primary-color;
  }

  &__link {
    padding: 10px 12px;
  }

  &__active-link {
    color: white;
  }

  &__next {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .pagination {
    flex-direction: column;
    > p {
      padding-right: 0;
    }
  }
}

@media (max-width: 480px) {
  .pagination {
    .pagination__container {
      justify-content: center;
      background-color: transparent;
      flex-wrap: wrap;
      box-shadow: none;
      .pagination__page {
        border: 1px solid #f3f3f3;
        background-color: #fff;
      }
      .pagination__active {
        background-color: $primary-color;
      }
      .pagination__next {
        border: 1px solid #f3f3f3;
        padding: 10px 20px;
        background-color: #fff;
      }
    }
  }
}
