@import "../../styles/colors.scss";

.selected-news {
  &__padding {
    padding: 0 35px;
  }

  &__separator {
    @include horizontal-separator;
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 30px 0;
    font-size: 20px;
    font-weight: bold;
  }

  &__description {
    p {
      margin: 0;
    }

    img {
      display: block;
      width: 100%;
      margin: 10px 0;
      object-fit: cover;
    }
  }

  &__metadata {
    display: flex;
    justify-content: space-between;

    &__left-container {
      color: $gray-text;

      svg {
        margin: 0 15px;
      }
    }

    &__social-link {
      cursor: pointer;
      margin-left: 20px;
      padding: 3px;
      border: 1px solid $gray-separator;
      border-radius: 5px;

      &:hover {
        background-color: $gray-separator;
      }
    }
  }

  &__comment-count {
    font-weight: bold;
    padding-bottom: 20px;
  }

  &__popular-news {
    margin: 20px 0 130px;
  }
}

@media (max-width: 767px) {
  .selected-news {
    &__metadata {
      flex-wrap: wrap;
    }
    &__padding {
      .manage-block {
        flex-direction: column;
        &__admin,
        &__control,
        &__manage {
          margin: 5px;
        }
      }
    }
  }
}
