@import "../../styles/colors.scss";

.product-description-card {
  position: relative;
  display: flex;
  padding: 30px 40px;

  &__rule {
    background: #ffa5009e;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 2px;
    color: #fff;
    margin: 10px 10px 10px 0;
  }

  &__discount {
    position: absolute;
    top: 18px;
    background-color: rgba(255, 90, 90, 0.2);
    color: #ff5a5a;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    padding: 2px 6px;
    border-radius: 3px;
    box-sizing: border-box;
  }

  &__img {
    max-width: 200px;
    img {
      cursor: pointer;
    }

    &__return-parent {
      padding: 10px 0;
      font-size: 15px;
      color: #a3a3a3;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #636363;
      }
    }

    &__large {
      width: 170px;
      height: 170px;

      figure {
        margin: 0;
        padding: 0;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          background-color: #fff;
          display: block;
          pointer-events: none;
        }
        &:hover img {
          opacity: 0;
        }
      }
    }

    &__small {
      img {
        background-color: #f7f7f7;
        width: 50px;
        height: 50px;
        object-fit: scale-down;
      }
    }

    &__flex {
      display: flex;
      padding-top: 5px;
      flex-wrap: wrap;
      justify-content: center;

      div {
        padding-right: 5px;
      }
    }
  }

  &__product-options {
    .product-option {
      padding: 5px 0;
      display: flex;
      &__check {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid #c2c2c2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
          .checked-yes {
            background-color: $primary-color-darker;
          }
          .checked-not {
            background-color: #a1a1a1;
          }
        }
        .check-div {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          transition: 0.3s ease-in-out;
        }
        .checked-yes {
          background-color: $primary-color;
          &:hover {
            background-color: $primary-color-darker;
          }
        }
        .checked-not {
          background-color: #fff;
          &:hover {
            background-color: #a1a1a1;
          }
        }
      }
      &__name {
        margin: 0 7px;
        max-width: 150px;
        overflow: hidden;
        width: 100%;
        border-right: 1px solid #c2c2c2;
      }
      &__price {
        padding-left: 10px;
      }
    }
  }

  &__separator {
    @include vertical-separator;
  }

  &__description {
    width: 100%;
    line-height: 1.5;

    &__title {
      font-weight: bold;
      font-size: 18px;
    }

    &__price {
      font-size: 18px;
      text-transform: uppercase;

      &__quantity {
        font-weight: bold;
        color: $primary-color;
        text-decoration: underline;

        &_special {
          text-decoration: none;
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__sku {
      font-size: 18px;
      text-transform: uppercase;
    }

    &__customer-type {
      &__radios {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-end;
        > div + div {
          margin-left: 10px;
        }

        input {
          margin: 0 10px;
        }
      }
    }

    &__control {
      padding: 10px 0;
      display: flex;
      align-items: center;
      &__basket {
        padding: 0 10px;
      }
      &__like {
        button {
          span {
            padding: 12px 0;
          }
        }
      }
    }

    &__company {
      &__name {
        a {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }
  }

  &__price {
    &_old {
      text-decoration: line-through;
      font-size: 14px;
      color: #cccccc;
    }
  }

  &__accordion {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    &__item {
      &__heading {
        &__button {
          display: flex;
          align-items: center;
          color: #444;
          cursor: pointer;
          padding: 5px 15px;
          text-align: left;
          border: none;

          &:focus {
            outline: none;
          }

          &__icon {
            margin-left: auto;

            div {
              display: flex;
              svg {
                align-self: center;
              }
            }
          }
        }
      }

      &__panel {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 7px 17px;

        &__organization {
          cursor: pointer;
        }
      }
    }
  }

  &__variations {
    padding: 7px 0;
    > div + div {
      margin-top: 10px;
    }
  }

  .product-variations-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    &__single-color {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #b0b0b0;
    }
    &__single-size-border {
      cursor: pointer;
      padding: 0 10px;
      border: 1px solid #b0b0b0;
    }

    &__current {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    > div + div {
      margin-left: 7px;
    }
  }

  &__product-size {
    display: flex;
    div {
      margin-right: 10px;
    }
    &__size {
      min-width: 50px;
    }
    &__color {
      display: flex;
      min-width: 50px;
      div {
        margin: 0 5px;
      }
    }
  }
  .product-multiple-share {
    button {
      margin: 0 2px;
      &:focus {
        outline: none;
      }
      &:hover {
        .product-multiple-share {
          &__facebook {
            background-color: #273a63;
          }
          &__twitter {
            background-color: #0083b5;
          }
        }
      }
    }
    display: flex;
    justify-content: flex-end;

    > svg + svg {
      margin-left: 5px;
    }

    &__twitter {
      transition: 0.1s ease-in;
      cursor: pointer;
      font-size: 18px;
      padding: 5px;
      border-radius: 50%;
      background-color: #00acee;
      fill: #fff;
    }
    &__facebook {
      transition: 0.3s ease-in;
      cursor: pointer;
      font-size: 18px;
      padding: 5px;
      border-radius: 50%;
      background-color: #3b5998;
      fill: #fff;
    }
  }
}
