.product-field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
  &__title {
    font-size: 18px;
    font-weight: bold;
  }
  &__add-new {
    a {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      > div {
        margin-left: 5px;
      }
    }
  }
}
