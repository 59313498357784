.login-with-google {
  padding: 2.5px 2px;
  cursor: pointer;
  button {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    &:focus {
      outline: none;
    }
    > div {
      padding: 0 !important;
      margin-right: 0 !important;
    }
    > span {
      padding: 0 !important;
    }
  }
}
