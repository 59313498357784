@import "../../styles/colors.scss";

.innovation-projects {
  &__body {
    padding-bottom: 100px;
    margin-top: 30px;

    &__title {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      background-color: $gray-background;
    }

    &__view {
      display: flex;
      align-items: center;
      padding-top: 15px;

      p {
        margin: 0;
        font-size: 13px;
      }

      &__block {
        padding: 0 10px;
      }
    }

    &__padding {
      padding: 0 30px;
    }
    &__title {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      background-color: $gray-background;
    }
  }
}

@media (max-width: 480px) {
  .innovation-projects {
    .pagination {
      flex-direction: column;
    }
  }
}
