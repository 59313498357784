.similar-products {
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    justify-content: space-between;
  }

  &__card {
    display: flex;
  }

  &__img-box {
    margin-right: 10px;
    overflow: hidden;

    width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  &__details {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__product-price {
    font-size: 14px;
    font-weight: bold;
  }

  &__product-add {
    width: 100%;
    margin-top: auto;
    padding-top: 5px;
  }
}
